import GlobalEvent from 'js-events-listener';
import { Moment } from 'moment';
import { useEffect, useRef } from 'react';

export const generateVoiceName = (socketCode, name) => {
  const voiceName = `${socketCode}-${name.replace(/\s/g, '')}`;
  return voiceName;
}

export const useGlobalEvent = (name, callback, arrayOfState = []) => {
  const listenerRef = useRef(null);
  useEffect(() => {
    listenerRef.current = GlobalEvent.on(name, callback);
    return () => {
      GlobalEvent.rm(listenerRef.current);
    };
  }, arrayOfState);
}

export const getTourToken = fullUrl => {
  if (!fullUrl || typeof fullUrl !== 'string') return fullUrl;
  const parts = fullUrl.split('/');
  return parts.slice(4, parts.length).join('/');
};

export const roundTimeToInterval = (time: Moment, interval: number, isUp: boolean) => {
  if (isUp) {
    const remainder = interval - (time.minute() % interval);
    if (remainder === interval) {
      return time.clone();
    }
    return time.clone().add(remainder, 'minutes');
  } else {
    const remainder = time.minute() % interval;
    return time.clone().subtract(remainder, 'minutes');
  }
}
