import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from "react-bootstrap";
import RequestHelper from '../../../utils/Request.Utils';
import { useGlobalEvent } from '../../../utils/Common.Utils';
import ChatSvg from '../../../assets/images/chat.svg';
import ChatDisableSvg from '../../../assets/images/chat-disable.svg';
import GlobalEvent from 'js-events-listener';
import './styles.scss';

const ChatImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;

  @media (max-width: 1199px) {
    width: 18px;
    height: 18px;
  }
`

const ChatSlideInPanel = () => {
  const [showChat, setShowChat] = useState(false);
  let { id } = useParams();
  const dispatch = useDispatch();
  const { userInfo, dialogState, virtualTourState } = useSelector((state: any) => ({
    userInfo: state.user,
    dialogState: state.dialog,
    virtualTourState: state.virtualTour
  })); 
  const [chatHistories, setChatHistories] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chattingEndRef = useRef(null);

  useGlobalEvent('SLIDE_CHAT_PANEL', () => {
    setShowChat(!showChat);
  }, []);

  useEffect(() => {
    GlobalEvent.emit('UPDATE_CHAT_DISPLAY_PANEL', showChat);
  }, [showChat]);

  useEffect(() => {
    if(!virtualTourState.socket) return;

    const socket = virtualTourState.socket;
    socket.on("CHAT", (res) => {
      console.log("CHAT", "RECEIVED MESSAGES");
      setChatHistories([...chatHistories, {payload: {Name: res.Name, Message: res.Message}}]);
      chattingEndRef.current.scrollIntoView(true);
      GlobalEvent.emit('CHAT_RECEIVED_MESSAGES');
    });
  }, [virtualTourState.socket, chatHistories]);

  useEffect(() => {

    async function fetchData() {
      const response = await RequestHelper.get(`/tour-session/${id}/messages`, {});
      if(!response.data.success){
        console.log(response.data.error);
      }else {
        let chat_histories = response.data.data.filter(item => item.action === 'CHAT').map(item => {return {...item, ...{payload: JSON.parse(item.payload)}}}).reverse();
        setChatHistories(chat_histories);
        chattingEndRef.current?.scrollIntoView(true);
      }
    }
    fetchData();
  }, [id, userInfo.token]); // eslint-disable-line

  const sendMessage = () => {
    if(!virtualTourState.socket) return;

    const socket = virtualTourState.socket;
    socket.emit("CHAT", {
      message: newMessage
    });
    setNewMessage('');
  }

  const handleKeyUp = (event) => {
    if(event.key === "Enter"){
      event.preventDefault();
      if(event.shiftKey){
        event.stopPropagation();
      }else {
        sendMessage();
      }
    }
  }

  const renderMessageText = text => {
    const urlReg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    if (!urlReg.test(text)) return text;
    const parts = text.match(urlReg);
    const partIndexs = parts.map(val => text.indexOf(val));
    const comps = [];
    parts.forEach((val, i) => {
      if (i === 0 && partIndexs[i] !== 0) {
        comps.push(
          text.slice(0, partIndexs[i])
        )
      }
      if (i > 0 && partIndexs[i-1] + parts[i-1].length < partIndexs[i]) {
        comps.push(
          text.slice(partIndexs[i-1] + 1, partIndexs[i])
        )
      }
      comps.push(
        <a href={val} target="_blank">{val}</a>
      );
      if (i === parts.length - 1 && partIndexs[i] + parts[i].length < text.length) {
        comps.push(
          text.slice(partIndexs[i] + 1, text.length)
        )
      }
    });
    return comps;
  }

  return (
    <div className={`chat-slide-in-panel ${showChat ? 'show' : 'hide'} d-flex flex-column`}>
      <div className="chatting-info d-flex flex-column pl-4 pr-2 py-2">
        <div className="chatting-history">
          <div className="message-wrapper">
            {chatHistories.map((historyItem, nIndex) => (
              <div key={nIndex} className="chating-text-box py-2 pr-3">
                {userInfo.user.name === historyItem.payload.Name ?
                  <h5><ChatImg src={ChatSvg} />Me</h5> :
                  <h5><ChatImg src={ChatDisableSvg} />{historyItem?.payload.Name}</h5>
                }
                <p className="m-0">
                  {historyItem?.payload.Message !== '' &&
                    historyItem.payload.Message.split('\n').map(i => {
                      return <span>{renderMessageText(i)}</span>
                    })
                  }
                </p>
              </div>
            ))}
            <div ref={chattingEndRef} />
          </div>
        </div>
        <div className="last-chat">
          <textarea
            className="my-1 float-left"
            placeholder="Type a message"
            value={newMessage}
            onKeyUp={(e) => handleKeyUp(e)}
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
          >
          </textarea>
          <div className="btn-container d-flex align-items-center justify-content-between mt-1 w-100 float-left">
            <Button className="btn-send btn-bugress-primary" onClick={() => sendMessage()}>Send</Button>

            <div style={{ cursor: 'pointer' }} onClick={() => setShowChat(false)}>
              Close →
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSlideInPanel;
