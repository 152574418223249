import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { videoChattingDialogAction } from '../../store/dialog/actions';
import { DialogNames } from '../../store/dialog/types';
import * as CONSTANTS from '../../constants';

const VideoChattingModal = (props) => {
  const dispatch = useDispatch();
  const { dialog } = useSelector((state: any) => ({
    dialog: state.dialog
  }));

  return (
    <Modal
      show={dialog.isOpened && dialog.name === DialogNames.VIDEO_CHATTING_DIALOG}
      onHide={() => { dispatch(videoChattingDialogAction({ isOpened: false })) }}
      animation
      centered
      className="voice-chatting-modal"
    >
      <Modal.Body>
        <p>Video Calling....</p> : ''
        <Button
          onClick={() => {
            dispatch(videoChattingDialogAction({
              isOpened: false,
            }))
            props.onAccept && props.onAccept();
          }}>
          Accept
        </Button>
        <div className="cancel-btn d-flex justify-content-center align-items-center mt-3">
          <a
            onClick={() => {
              dispatch(videoChattingDialogAction({
                isOpened: false,
              }))
            }}
          >
            Decline
          </a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VideoChattingModal;