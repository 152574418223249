import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';


import './StopSessionModal.scss';

interface Props {
  open: boolean,
  onClose(): void,
  onConfirm(): void,
  message: string,

  [additionProps: string]: any,
}

const Dialog = (props : Props) => {

  return (
    <CustomModal
      show={props.open}
      onHide={props.onClose}
      centered
      backdrop="static"
      className="reset-password-modal"
    >
      <Modal.Body className="stop-session-modal">
        <p className="text-center">{props.message}</p>
        <div className="middle-row">
          <button onClick={props.onClose}>Cancel</button>
          <button className="confirm-btn" onClick={() => {
            props.onConfirm();
            props.onClose();
          }}>Confirm</button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};

const CustomModal = styled(Modal)`
  .form-group {
    margin-bottom: 0.8rem;

    label {
      margin: 0;
      color: white;
      opacity: 0.8;
      font-size: 0.8rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
      border-bottom: 1px solid white;
      border-radius: 0;
      padding: 0;
      color: white;
      height: 1.5rem;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  Button {
    background: white;
    width: 100%;
    color: orange;
    margin-top: 1rem;
    border: 1rem;
    height: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .signin-btn {
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`

export default Dialog;
