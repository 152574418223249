import React, { useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import IconShare from '../../assets/images/new-icons/share.svg';
import * as CONSTANT from '../../constants';
import { useParams } from "react-router-dom";
import GlobalEvent, { useEvent, useGlobalState } from 'js-events-listener/react';
import { useSelector } from 'react-redux';
import DefaultAvatarSvg from '../../assets/images/default-avatar-filled.svg';
import DefaultAvatar from '../../sharedComponents/DefaultAvatar/DefaultAvatar';
import Logger from '../../components/VirtualTourGroup/MainPanel/Logger';

type Props = {
  isShow: boolean,
  hideModal: Function,
  isGroup?: boolean,
  shouldClaimBack?: boolean,
}

const TransferModal = ({ isShow, hideModal, isGroup, shouldClaimBack }: Props) => {
  // @ts-ignore
  const { id } = useParams();
  const logger = useRef(new Logger(id));
  const tourSlug = (() => {
    const parts = window.location.pathname.split('/');
    return parts[parts.length - 1];
  })();
  const [listUser] = useGlobalState([], 'group-list-users-'+tourSlug);
  const { userInfo, virtualTourState } = useSelector((state: any) => ({
    userInfo: state.user,
    virtualTourState: state.virtualTour
  }));
  const [userAddNameModalData] = useGlobalState({
    open: false,
    name: '',
    email: '',
    avatar: '',
  }, 'guest-name');
  const loggedUser = useRef({
    createdBy: '',
    id: 0,
    email: ''
  });
  useEffect(() => {
    loggedUser.current = {
      id: userInfo.user.ID || 0,
      email: userAddNameModalData.email || userInfo.user.email,
      createdBy:userAddNameModalData.name || userInfo.user.name,
    }
  }, [userAddNameModalData.name, userInfo.user])

  const getMyControlID = () => {
    if (userAddNameModalData.name) return userAddNameModalData.name;
    return `${userInfo.user.ID}-${userInfo.user.name}`;
  };
  const getUserControlID = (val) => {
    if (val.user.ID === 0) return val.name;
    return `${val.user.ID}-${val.user.name}`
  }

  const transferGroupControl = (val) => {
    if (!virtualTourState.socketCode) return;
    if (!virtualTourState.socket) return;
    const controlId = getUserControlID(val);
    const socket = virtualTourState.socket;
    logger.current?.add('TRANSFER_CONTROL_IN_GROUP', { controlId }, loggedUser.current);
    socket.emit("SESSION_PING", {
      data: {
        event: 'TRANSFER_CONTROL_IN_GROUP',
        controller: controlId,
      },
    });
    hideModal(false);
  }

  const onClaimBackControl = () => {
    if (!virtualTourState.socketCode) return;
    if (!virtualTourState.socket) return;
    const controlId = getMyControlID();
    const socket = virtualTourState.socket;
    socket.emit("SESSION_PING", {
      data: {
        event: 'TRANSFER_CONTROL_IN_GROUP',
        controller: controlId,
      },
    });
    logger.current?.add('CLAIM_BACK_CONTROL', {}, loggedUser.current);
    hideModal(false);
  };

  if (isGroup) {
    return (
      <CustomModal
        show={isShow}
        onHide={() => hideModal(false)}
        centered
      >
        <Modal.Body>
          <Title>Transfer Control?</Title>
          {shouldClaimBack ? (
            <>
              <Description>
                Do wish to claim back control of the tour?
              </Description>
              <ControlDiv>
              <ButtonNo variant="outline-primary" onClick={() => hideModal(false)}>No</ButtonNo>
                <img src={IconShare} style={{ width: '59px', height: '47px', marginLeft: '32px', marginRight: '32px' }} alt="" />
                <ButtonYes variant="outline-primary" onClick={() => {
                  onClaimBackControl();
                }}>Yes</ButtonYes>
              </ControlDiv>
            </>
          ) : (
            <>
              <Description>
                Do you wish to transfer control of the tour? Please tap one user in the list below
              </Description>
              <div style={{ height: 200, overflowY: 'auto' }}>
                {listUser.map((val, i) => {
                  if (getUserControlID(val) === getMyControlID()) return null;
                  return (
                    <UserRowDiv onClick={() => transferGroupControl(val)}>
                      {!val.avatar && !val.user.avatar ? (
                        <DefaultAvatar width={50} height={50} style={{ margin: 'auto' }} />
                      ) : (
                        <img src={val.avatar || val.user.avatar} alt="" style={{ width: 50, height: 50, borderRadius: 25, margin: 'auto', objectFit: 'cover' }} />
                      )}
                      <div style={{ flex: 1, paddingLeft: 10 }}>{val.name}</div>
                    </UserRowDiv>
                  );
                })}
              </div>
            </>
          )}
        </Modal.Body>
      </CustomModal>
    );
  }
  return (
    <CustomModal
      show={isShow}
      onHide={() => hideModal(false)}
      centered
    >
      <Modal.Body>
        <Title>Transfer Control?</Title>
        <ControlDiv>
          <ButtonNo variant="outline-primary" onClick={() => hideModal(false)}>No</ButtonNo>
          <img src={IconShare} style={{ width: '59px', height: '47px', marginLeft: '32px', marginRight: '32px' }} alt="" />
          <ButtonYes variant="outline-primary" onClick={() => hideModal(true)}>Ok</ButtonYes>
        </ControlDiv>
        <Description>
          Do you wish to transfer control of the tour? Please tap the <span>Ok</span> to transfer to the other user.
        </Description>
      </Modal.Body>
    </CustomModal>
  )
}

const CustomModal = styled(Modal)`
  width: 417px;
  border-radius: 12px;  
  left: calc(50% - 208px) !important;

  .modal-content {
    background: white;

    .modal-body {
      padding: 23px 66px;
    }  
  }
`

const UserRowDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

const Title = styled.h4`
  line-height: 42px;
  color: #2E2D2C;
  font-size: 29px;
`
const ControlDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`

const ButtonNo = styled(Button)`
  width: 82px;
  height: 44px;
  border-radius: 11px;
  color: ${CONSTANT.COLOR.BG};
  border: 2px solid ${CONSTANT.COLOR.BG};
  font-weight: 700;
  font-size: 20px;
  background-color: transparent;  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover, &:active, &:focus {
    background-color: ${CONSTANT.COLOR.BG} !important;
    border-color: ${CONSTANT.COLOR.BG} !important;
    color: white !important;
  }
`

const ButtonYes = styled(Button)`
  width: 82px;
  height: 44px;
  border-radius: 11px;
  color: ${CONSTANT.COLOR.MAIN};
  border: 2px solid ${CONSTANT.COLOR.MAIN};
  font-weight: 700;
  font-size: 20px;
  background-color: transparent;    
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover, &:active, &:focus {
    background-color: ${CONSTANT.COLOR.MAIN} !important;
    border-color: ${CONSTANT.COLOR.MAIN} !important;
    color: white !important;
  }
`

const Description = styled.p`
  margin-top: 19px;
  font-size: 16px;
  line-height: 18px;
  span {
    font-weight: 700;
  }
`
export default TransferModal;