import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { RouteComponentProps, Link, withRouter, useHistory } from "react-router-dom";

import { loginUserDialogAction, resetPasswordDialogAction, editUserDialogAction } from '../../store/dialog/actions';
import { updateUserAction, logoutUserAction } from '../../store/user/actions';
import * as CONSTANT from '../../constants';
import RequestHelper from '../../utils/Request.Utils';
import UserSvg from '../../assets/images/users.svg';
import LogoutSvg from '../../assets/images/signs.svg';

import DefaultAvatar from '../DefaultAvatar/DefaultAvatar';

import EditUserDialog from '../EditUserModal';
import CONFIG from '../../config';

const qs = require('qs');

interface IAddtionProps {
  renderLeft?(): any,
  renderRight?(): any,
  hasBack?: boolean,
}

function LogoutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30.234}
      height={30.233}
      viewBox="0 0 30.234 30.233"
      {...props}
    >
      <path
        data-name="Path 45"
        d="M29.288 14.174H17.321a.945.945 0 010-1.89h11.967a.945.945 0 010 1.89zm0 0"
      />
      <path
        data-name="Path 46"
        d="M24.564 18.896a.945.945 0 01-.668-1.614l4.056-4.056-4.056-4.056a.945.945 0 111.337-1.336l4.724 4.724a.945.945 0 010 1.336l-4.724 4.724a.939.939 0 01-.669.278zm0 0"
      />
      <path
        data-name="Path 47"
        d="M10.078 30.233a2.6 2.6 0 01-.781-.117l-7.581-2.524A2.543 2.543 0 010 25.192V2.519A2.522 2.522 0 012.52 0a2.6 2.6 0 01.78.117l7.581 2.526a2.542 2.542 0 011.716 2.4v22.671a2.522 2.522 0 01-2.519 2.519zM2.52 1.892a.632.632 0 00-.63.63v22.67a.655.655 0 00.437.607l7.546 2.514a.684.684 0 00.206.028.632.632 0 00.63-.63V5.039a.655.655 0 00-.437-.607L2.725 1.917a.684.684 0 00-.205-.025zm0 0"
      />
      <path
        data-name="Path 48"
        d="M19.207 10.078a.945.945 0 01-.945-.945V3.464a1.577 1.577 0 00-1.57-1.572H2.52a.945.945 0 110-1.89h14.172a3.467 3.467 0 013.464 3.464v5.667a.945.945 0 01-.949.945zm0 0"
      />
      <path
        data-name="Path 49"
        d="M16.692 26.454h-5.039a.945.945 0 110-1.89h5.039a1.577 1.577 0 001.574-1.575V17.32a.945.945 0 011.89 0v5.669a3.467 3.467 0 01-3.464 3.465zm0 0"
      />
    </svg>
  )
}

const NavMenu = (props : IAddtionProps & RouteComponentProps) => {
  const { location, hasBack } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [curPath, setCurPath] = useState(location.pathname);
  const { userInfo } = useSelector((state: any) => ({
    userInfo: state.user
  }))

  useEffect(() => {
    if (location.pathname === '/reset-password') {
      const params = qs.parse(location.search);
      dispatch(resetPasswordDialogAction({ isOpened: true, code: params['?code'] }));
    } else {
      if (!localStorage.token) {
        dispatch(loginUserDialogAction(true));
      } else {
        if (userInfo.token === "") {
          // RequestHelper
          //   .post('/tour-session/clear-all/t3339yw4kn3vjmmz', {})
          //   .then((res) => {
          //     console.log(res.data);
          //   })
          //   .catch(error => console.log(error));

          RequestHelper
            .get('/users/me', {})
            .then((res) => {
              if(res.data.success) dispatch(updateUserAction({token: localStorage.token, user: res.data.data}));
              else {
                handleLogout();
              }
            })
            .catch(error => console.log(error));
        }
      }
    }
  }, []) // eslint-disable-line

  const handleLogout = () => {
    dispatch(logoutUserAction());

    if (userInfo.user.role === CONSTANT.UserRoles.broker) {
      if (location.pathname === '/dashboard')
        window.location.reload()
      else
        window.location.pathname = '/dashboard';
    } else {
      window.location.pathname = '/dashboard';
    }
  }

  return (
    <>
    <Navbar id="burgess-nav" bg="dark" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="navbar-bugress">
        {props.renderLeft ? (
          props.renderLeft()
        ) : (
          <Nav>
          
          <DefaultAvatar width={40} height={40} fill="white" style={{ cursor: 'pointer' }} onClick={() => { !!userInfo.user.name && dispatch(editUserDialogAction(true)) }}  />
          {/* <img src={UserSvg} alt="User" style={{ cursor: 'pointer' }} onClick={() => { !!userInfo.user.name && dispatch(editUserDialogAction(true)) }} /> */}

          {userInfo.user.name && (
            <div style={{ cursor: 'pointer' }} onClick={() => { dispatch(editUserDialogAction(true)) }}>
              <h5 className="ml-2 mb-0">{userInfo.user.name}</h5>
              {userInfo.user.jobTitle && <h6 className="ml-2 mt-1">{userInfo.user.jobTitle}</h6>}
            </div>
          )}
          {/* {hasBack && <Nav.Link href="/" style={{ color: '#ff0050' }}>Virtual Tours</Nav.Link>} */}
        </Nav>
        )}
        <Navbar.Brand className="bugres-brand" >
          <a href="/"><img src={CONFIG['IMAGE']} style={{ height: 30, width: 'auto' }} alt="Theatro360" /></a>
        </Navbar.Brand>

        {props.renderRight ? props.renderRight() : (
          <Nav>
            {userInfo.token !== '' ? (
              <Nav.Link className="logout" onClick={() => handleLogout()}>
                <span className="mr-2">Logout</span>
                {/* <img src={LogoutSvg} alt="Logout" /> */}
                <LogoutIcon fill={'white'} />
              </Nav.Link>
            ) : (
              <Nav.Link className="login" onClick={() => { dispatch(loginUserDialogAction(true)) }}>
                <span className="mr-2">Login</span>
              </Nav.Link>
            )}
          </Nav>
        )}
      </Navbar.Collapse>
      
    </Navbar>
    <EditUserDialog />
    </>
  );
}

export default withRouter(NavMenu);