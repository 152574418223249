
export const SITE = (() => {
  if (window.location.hostname.includes('burgess')) return 'burgess';
  if (window.location.hostname.includes('accor')) return 'accor';
  return 'theatro';
})();

export const COLOR = (() => {
  if (SITE === 'theatro' || SITE === 'accor') return {
    MAIN: 'var(--primary-color)',
    SECOND: '#92DCE5',
    BLUE: '#00D3E4',
    GREY: '#444444',
    BG: 'var(--background-color)',
    BG_DANGER: 'var(--background-color)',
  };

  if (SITE === 'burgess') return {
    MAIN: '#00AFD3',
    SECOND: '#92DCE5',
    BLUE: '#00D3E4',
    GREY: '#444444',
    BG: '#00AFD3',
    BG_DANGER: 'red',
  };
})();

export const ROLES = {
  client: 'Guest',
  broker: 'Host',
  'pending-broker': 'Host (pending)',
  admin: 'Admin',
}

export const VIRTUAL_TOUR_CONTROL_EVENT = {
  INIT: "init",
  START: "start",
  GOTO: "goto",
  STOP: "stop",
  PAUSE: "pause",
};

export const TOUR_STATUS = {
  PENDING: "Pending",
  CONNECTED: "Connected",
  COMPLETED: "Completed",
};

export const UserRoles = {
  broker: "broker",
  'pending-broker': "pending-broker",
  client: "client",
  admin: "admin",
};

export const VoiceCallActions = {
  call: "call",
  accept: "accept",
  decline: "decline",
  hangup: "hangup",
  start: "start",
};

export const VoiceCallRoles = {
  master: "master",
  slave: "slave",
};

export const HOME_TOURS2 = [
  {
    name: "SULTANA",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "81-DN0T",
    imgUrl: "https://theatro360-uploads.s3.eu-west-2.amazonaws.com/jGJnqpIQWovndz60Cg4ysKdn.jpg?u=bceda2624c46bf66bb85b5ff5cbb4cf4",
    support3D: true,
  },
  {
    name: "M Gallery, Windsor",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "ON-0JLD",
    imgUrl: "https://theatro360-uploads.s3.eu-west-2.amazonaws.com/rxqeFkkRnWFHNZasb8IUqydr.jpeg?u=bfbe4bbce226cade2278b6f8932c871f",
  },
  {
    name: "ROE & CO TEASER",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "3R-9G4A",
    imgUrl: "https://touchtour-assets.s3-eu-west-1.amazonaws.com/Roe%26Co-Exterior.jpg",
  },
  {
    name: "Club Lounge // SFO",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "KO-KL7U",
    imgUrl: "https://touchtourdev.imgix.net/5d1ef170971c4090988220.jpg",
  }
];

export const HOME_TOURS1 = [
  {
    name: "SECRET",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "NY-6B28",
    imgUrl:
      "https://burgess.theatro360.com/tour/assets/background-secret.jpg",
  },
  {
    name: "Project Timeline",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "AM-437D",
    imgUrl:
      "https://theatro360-assets.imgix.net/4Yhoj1gH050u4ny2UqietF9E.jpg?1617038345",
  },
  {
    name: "STELLA MIA",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "06-PFWD",
    imgUrl:
      "https://theatro360-uploads.s3.eu-west-2.amazonaws.com/TJU0eZv5l5IPvF15BHefGf0l.jpg?u=61bc1bbf37963cf116a017159ba01853",
  },
  {
    name: "ODYSSEY II",
    type: "For Sale and charter",
    info: {
      length: "82.5m (270.6ft)",
      year: 2013,
      content: "Abeking & Rasmussen, 12 guests",
    },
    token: "54-02LL",
    imgUrl:
      "https://movein.theatro360.com/uploads/event_images/5e173ca06a7dd028615158.jpg",
  },
  {
    name: "HASNA",
    type: "For Sale and charter",
    info: {
      length: "73m (239.5ft)",
      year: 2017,
      content: "Feadship, Royal Van Lent, The Netherlands",
    },
    token: "TL-KOPF",
    imgUrl:
      "https://movein.theatro360.com/uploads/event_images/5e9d8946bdd5a890167925.jpg",
  },
  {
    name: "MISS MONEYPENNY V",
    type: "For Sale and charter",
    info: {
      length: "73m (239.5ft)",
      year: 2017,
      content: "Feadship, Royal Van Lent, The Netherlands",
    },
    token: "BH-7YAP",
    imgUrl:
      "https://movein.theatro360.com/uploads/event_images/5ecb9ab2380b7890130350.jpg",
  },
  {
    name: "AKIKO",
    type: "For Sale and charter",
    info: {
      length: "73m (239.5ft)",
      year: 2017,
      content: "Feadship, Royal Van Lent, The Netherlands",
    },
    token: "WN-GOJM",
    imgUrl:
      "https://movein.theatro360.com/uploads/event_images/5ecd7edaae821687161889.jpg",
  },
  {
    name: "YALLA",
    type: "For Sale and charter",
    info: {
      length: "73m (239.5ft)",
      year: 2017,
      content: "Feadship, Royal Van Lent, The Netherlands",
    },
    token: "FL-Q8FY",
    imgUrl:
      "https://theatro360-uploads.s3.eu-west-2.amazonaws.com/7HSZNuIBlckBhQxRt6wgK3TX.jpg?u=6a76b49ead9071b3fa80a239d82ad8eb",
  },
];

export const HOME_TOURS = [...HOME_TOURS2, ...HOME_TOURS1];

export const TOUR_HOME_PAGE = "Tour Home Page";
export const CONNECTING_PAGE = "Connecting page";
export const CONNECTED_PAGE = "Connected page";
export const TOUR_START_PAGE = "Tour Start Page";
export const GO_TO_TOUR_PAGE = "Go To Tour Page";
export const TOUR_PLAY_PAGE = "Tour Play Page";
export const TOUR_PAUSE_PAGE = "Tour Pause Page";
export const TOUR_STOP_PAGE = "Tour Stop Page";
export const WELCOME_PAGE = "Welcome page";

export const TOURS = [
  {
    id: "1",
    status: "Pending",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "2",
    status: "Pending",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "3",
    status: "Connected",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "4",
    status: "Connected",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "5",
    status: "Available",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "6",
    status: "Available",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "7",
    status: "Unavailable",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "8",
    status: "Unavailable",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "9",
    status: "Complete",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "10",
    status: "Complete",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "11",
    status: "Complete",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "12",
    status: "Complete",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "13",
    status: "Complete",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "14",
    status: "Complete",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "15",
    status: "Complete",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "16",
    status: "Complete",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "16",
    status: "Complete",
    broker: "Tim Vickers",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
  {
    id: "17",
    status: "Complete",
    broker: "James Smith",
    client: "Pending",
    email: "",
    telephone: "",
    name: "HASANA",
    date: "",
  },
];
