import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Constants from '../../../constants';
import DefaultAvatarSvg from '../../../assets/images/default-avatar-filled.svg';
import RequestHelper from '../../../utils/Request.Utils';
import classnames from 'classnames';
import GlobalEvent, { useEvent, useGlobalState } from 'js-events-listener/react';
import './UserPanel.scss';
import ArrowSVG from '../../../assets/images/arrow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Dialog from '../MainPanel/Dialog';
import DefaultAvatar from '../../../sharedComponents/DefaultAvatar/DefaultAvatar';

const UserPanel = forwardRef((props, ref) => {
  const { userInfo, virtualTourState } = useSelector((state: any) => ({
    userInfo: state.user,
    virtualTourState: state.virtualTour
  })); 
  const tourSlug = (() => {
    const parts = window.location.pathname.split('/');
    return parts[parts.length - 1];
  })();
  const [listUser, setListUser] = useGlobalState([], 'group-list-users-'+tourSlug);
  const [showUserPanel, setShowUserPanel] = useState(true);
  const [kickUserDialog, setKickUserDialog] : any = useState({
    showModal: false,
    userData: undefined,
  });

  const getUsers = async () => {
    const res = await RequestHelper.get(`/tour-session/${virtualTourState.tourSession?.ID}/members`, {});
    console.log('res', res);
    if (res.data.success) {
      setListUser(res.data.data);
    }
  };

  useImperativeHandle(ref, () => ({
    getUsers: () => {
      getUsers();
    }
  }));

  useEvent('VIDEO_GROUP_DISPLAY_ACTION', (mode) => {
    console.log('mode', mode)
    setShowUserPanel(mode === 'HIDDEN' ? true : false);
  }, [])

  useEffect(() => {
    GlobalEvent.emit('MEMBER_INFO', listUser);
  }, [listUser]);

  const roleWordings = (role) => {
    return !role ? Constants.ROLES.client : Constants.ROLES[role];
  }

  const kickThisUser = (val) => {
    if (!val) return;
    if (!virtualTourState.socket) return;
    virtualTourState.socket.emit("MEMBER_PING", {
      event: 'KICK_USER',
      data: {
        isMainBroker: false,
        controlID: (() => {
          if (val.name && val.user.ID) return `${val.user.ID}-${val.name}`;
          return val.name;
        })(),
      },
    });
  };

  return (
    <div className="left-panel user-panel-group d-flex flex-column mr-4 position-relative">
      {listUser.length > 0 && (
        <div className="d-flex flex-column align-items-center pt-2">
          {showUserPanel && listUser.map((val, i) => (
            <div key={`user-`+i} className="d-flex flex-column justify-content-center mb-2">
              <div
                className={classnames(
                  "position-relative d-flex flex-column justify-content-start",
                )}
              >
                <div style={{ width: 100, height: 100, position: 'relative', margin: 'auto', marginBottom: 5 }}>
                  {(!!val.avatar || !!val.user.avatar) ? (
                    <img src={val.avatar || val.user.avatar} alt="" style={{ width: 100, height: 100, borderRadius: 50, margin: 'auto', objectFit: 'cover' }} />
                  ) : (
                    <DefaultAvatar width={100} height={100} />
                  )}
                  
                  {virtualTourState.tourSession?.broker.ID === userInfo.user.ID && val.user.ID !== userInfo.user.ID &&
                  <div
                    style={{
                      position: 'absolute', top: 10, right: 7.5, borderRadius: '100%',
                      width: 20, height: 20, backgroundColor: Constants.COLOR.BG_DANGER,
                      display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                    }}
                    onClick={() => setKickUserDialog({ showModal: true, userData: val })}
                  >
                    <FontAwesomeIcon color="white" style={{ fontSize: 12 }} icon={faTimes} />
                  </div>}
                </div>
              </div>
              <div >
                <h5 className="name mb-0">{val.name}</h5>
                <p className="mb-0 role">{val.user?.jobTitle || roleWordings(val.user?.role)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <Dialog
        open={kickUserDialog.showModal}
        onClose={() => setKickUserDialog({ ...kickUserDialog, showModal: false })}
        message={"Are you sure you want to remove user: " + kickUserDialog.userData?.name}
        onConfirm={() => kickThisUser(kickUserDialog.userData)}             
      />
    </div>
  )
});

export default UserPanel;
