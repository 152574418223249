import React, { useState, useRef } from 'react';
import { useEvent } from 'js-events-listener/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './OnlineOfflineNotice.scss';

const OnlineOfflineNotice = () => {
  const options : any = {
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  useEvent('USER_ONLINE', name => {
    toast.info(name + " has entered the room", options);
  }, []);
  useEvent('USER_OFFLINE', name => {
    toast.info(name + " has left the room", options);
  }, []);
  useEvent('USER_ONLINE_VIDEO_CALL', name => {
    toast.info(name + " has entered the video chat", options);
  }, []);
  useEvent('USER_OFFLINE_VIDEO_CALL', name => {
    toast.info(name + " has left the video chat", options);
  }, []);
  return <ToastContainer autoClose={false} toastClassName="online-offline-toast-container" progressStyle={{ backgroundColor: 'white' }} />;
}

const OnlineOfflineNoticeOld = () => {
  const [usersOnline, setUsersOnline] = useState([]);
  const [usersOffline, setUsersOffline] = useState([]);
  const [showOnline, setShowOnline] = useState(false);
  const [showOffline, setShowOffline] = useState(false);
  const refTimeoutOnline = useRef(null);
  const refTimeoutOffline = useRef(null);
  useEvent('USER_ONLINE', name => {
    clearTimeout(refTimeoutOnline.current);
    console.log('USER_ONLINE', name);
    const currentOnline = usersOnline.slice();
    currentOnline.push(name);
    setUsersOnline(currentOnline);
    setShowOnline(true);
    refTimeoutOnline.current = setTimeout(() => {
      setUsersOnline([]);
      setShowOnline(false);
    }, 2000);
  }, []);
  useEvent('USER_OFFLINE', name => {
    clearTimeout(refTimeoutOffline.current);
    const currentOffline = usersOffline.slice();
    currentOffline.push(name);
    setUsersOffline(currentOffline);
    setShowOffline(true);
    refTimeoutOffline.current = setTimeout(() => {
      setUsersOffline([]);
      setShowOffline(false);
    }, 2000);
  }, []);
  return (
    <div style={{ height: 40 }}>
      {showOnline &&
      <p className="fade-in" style={{ margin: 0 }}>
        {usersOnline.map((name, i) => (
          <span
            key={"user-"+i}
          >
            {name}
            {i === usersOnline.length -1 ? null : <span>, </span>}
          </span>
        ))} has entered the session
      </p>}
      {showOffline &&
      <p className="fade-in" style={{ margin: 0 }}>
        {usersOffline.map((name, i) => (
          <span
            key={"user-"+i}
          >
            {name}
            {i === usersOffline.length -1 ? null : <span>, </span>}
          </span>
        ))} has left the session
      </p>}
    </div>
  );
};

export default OnlineOfflineNotice;
