import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { useGlobalState, useEvent } from 'js-events-listener/react';
import { Button, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import RequestHelper from '../../../utils/Request.Utils';
import { getTourToken } from '../../../utils/Common.Utils';
import Logger from './Logger';

import './styles.scss';

import { 
  setTourControllerAction,
  setEventTypeAction
} from '../../../store/virtualTour/actions';
import * as CONSTANTS from "../../../constants";
import CONFIG from '../../../config';
import svgBtnLock from '../../../assets/images/btn_lock.svg';
import ActionPanel from "./ActionPanel";
import BtnPanel from "./BtnPanel";
import ChatAndSwitchBtnPanel from './ChatAndSwitchBtnPanel';
import ChatSlideInPanel from '../ChattingPanel/ChatSlideInPanel';
import OnlineOfflineNotice from './OnlineOfflineNotice';
import WhoInControl from './WhoInControl';
// import OptionModal from "../../../sharedComponents/OptionModal";
import TransferModal from "../../../sharedComponents/TransferModal";


declare var TourSDK;

const MainPanel = ({ isCalling, disableVoiceCall, onLeaveVideoCall }) => {
  // @ts-ignore
  const { id } = useParams();
  const logger = useRef(new Logger(id));
  const [allTours, setAllTours] = useGlobalState([], 'group-all-tours');
  const [showTransferModal, setShowTransferModal] = useState({ show: false, claimBack: false });
  const [tourState, setTourState] = useState({
    tour: {} as any,
    tourToken: '',
    url: '',
  });
  const iframeRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [alert, setAlert] = useState({
    isShow: false,
    msg: ''
  })
  const [lockControl, setLockControl] = useGlobalState(true, 'group-lock-control');
  const [userAddNameModalData] = useGlobalState({
    open: false,
    name: '',
    email: '',
    avatar: '',
  }, 'guest-name');
  const loggedUser = useRef({
    createdBy: '',
    id: 0,
    email: ''
  });
  

  const { virtualTourState, userState } = useSelector((state: any) => ({
    userState: state.user,
    virtualTourState: state.virtualTour
  }));

  const myControlID = useRef('');
  useEffect(() => {
    if (userAddNameModalData.name) myControlID.current = userAddNameModalData.name;
    else myControlID.current = `${userState.user.ID}-${userState.user.name}`;
    loggedUser.current = {
      id: userState.user.ID || 0,
      email: userAddNameModalData.email || userState.user.email,
      createdBy:userAddNameModalData.name || userState.user.name,
    }
  }, [userAddNameModalData.name, userState.user])

  const getMyControlID = () => {
    return myControlID.current;
  };

  useEffect(() => {
    return () => {
      localStorage.setItem("controller", "")
    };
  }, [])

  useEffect(() => {
    (async () => {
      if (!virtualTourState.tourSession) return;
      const { tourGroup } = virtualTourState.tourSession;
      const res = tourGroup === 0 ? await RequestHelper.get('/tours/list', {})
        : await RequestHelper.get('/tours/group/'+tourGroup, {});
      if (res.data?.success) {
        const allGroupTours = tourGroup === 0 ? res.data?.data?.list.map(val => ({
          ...val,
          id: val.ID,
          tourToken: (() => {
            const parts = val.tourUrl.split('/');
            return parts[parts.length - 1];
          })(),
        })) : res.data?.data?.tours.map(val => ({
          ...val,
          id: val.ID,
          tourToken: (() => {
            const parts = val.tourUrl.split('/');
            return parts[parts.length - 1];
          })(),
        }));
        setAllTours(allGroupTours);
      }
    })();
  }, [virtualTourState.tourSession])

  useEffect(() => {
    if(!virtualTourState.tourSession || !virtualTourState.socket || !virtualTourState.tourToken) return;
    // console.log("USE EFFECT DEBUG", [virtualTourState.tourSession, virtualTourState.tourToken, virtualTourState.socket, allTours]);
    // if (allTours.length === 0) return;
    if (virtualTourState.tourToken !== tourState.tourToken && !!tourState.tourToken) return;
    const socket = virtualTourState.socket;
    const selectedTour = CONSTANTS.HOME_TOURS.filter(tour => tour.name === virtualTourState.tourSession.tourName)[0];

    const tourData = allTours.find(val => val.tourToken === virtualTourState.tourToken);
    let embedUrl = '';
    if (virtualTourState.tourToken === '81-DN0T' || ( !!tourData && tourData.support3D)) {
      embedUrl = `${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${virtualTourState.tourToken}/?3D=true&sdk_enable=1&shared_tour_id=${virtualTourState.tourSession.ID}`;
      // setEmbedUrl(`https://dev.theatro360.com/tour/VY-3WQG/10761?sdk_enable=1&shared_tour_id=${virtualTourState.tourSession.ID}`);
    } else {
      embedUrl = `${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${virtualTourState.tourToken}/?sdk_enable=1&shared_tour_id=${virtualTourState.tourSession.ID}`;
      // setEmbedUrl(`https://dev.theatro360.com/tour/VY-3WQG/10761?sdk_enable=1&shared_tour_id=${virtualTourState.tourSession.ID}`);
    }
    setTourState({
      tour: selectedTour,
      tourToken: virtualTourState.tourToken,
      url: embedUrl,
    })
    
    socket.on("SWITCH_TOUR", data => {
      logger.current?.add('SWITCH_TOUR', data, loggedUser.current);
      console.log("SWITCH_TOUR");
      const token = getTourToken(data.url);
      const tour = CONSTANTS.HOME_TOURS.filter(tour => tour.token === token)[0];
      setTourState({
        tour,
        tourToken: token,
        url: `${data.url}/?sdk_enable=1&shared_tour_id=${virtualTourState.tourSession.ID}`,
      })
      dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.INIT));
    })
  }, [virtualTourState.tourSession, virtualTourState.tourToken, virtualTourState.socket, allTours]) // eslint-disable-line

  useEffect(() => {
    initTourSession(virtualTourState.socket, tourState.tourToken);
  }, [virtualTourState.socket, tourState.tourToken]) // eslint-disable-line

  // const handleEvent = (eventType) => {
  //   switch (eventType) {
  //     case CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.INIT: {
  //       console.log('virtualTourState.socket.connect()');
  //       virtualTourState.socket.connect();
  //       logger.current?.add('CONNECT', {}, loggedUser.current);
  //       virtualTourState.socket.emit("TOUR_CONTROL", {
  //         event: "THUMBNAIL_PLAY_CLICK",
  //         data: null
  //       })
  //       logger.current?.add('THUMBNAIL_PLAY_CLICK', {}, loggedUser.current);
  //       break;
  //     }
  //     case CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.GOTO: {
  //       const win = window.open(`${CONFIG['BASE_URL']}/tour/view/${tourToken}` , '_blank');
  //       win.focus();
  //       dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.GOTO));
  //       break;
  //     }
  //     case CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.STOP: {
  //       virtualTourState.socket.disconnect();
  //       dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.INIT));
        
  //       if(userState.user.role === CONSTANTS.UserRoles.broker)
  //         history.push("/dashboard");
  //       else
  //         history.push("/");
  //       break;
  //     }
  //     default:
  //       dispatch(setEventTypeAction(eventType));
  //       break;
  //   }
  // };

  const initTourSession = (socket, token) => {
    const cleanId = !token ? '' : `tour-${token}`.replace(/\//g, '');
    if(!token || iframeRef.current.id !== cleanId) return;
    const tourControl = new TourSDK('#'+cleanId, CONFIG["TOUR_DEVSERVER_URL"]);
    //@ts-ignore
    window.tourControl = tourControl;
    tourControl.on('PLAYER_START_AUTO_SPIN', (data) => {
      if(getMyControlID() === localStorage.controller){
        console.log('tour auto plays');
        socket.emit("TOUR_CONTROL", {
          event: "PLAYER_START_AUTO_SPIN",
          data,
        });
      }
    });
  
    tourControl.on('PLAYER_STOP_AUTO_SPIN', (data) => {
      if(getMyControlID() === localStorage.controller){
        console.log('tour stops auto play');
        socket.emit("TOUR_CONTROL", {
          event: "PLAYER_STOP_AUTO_SPIN",
          data,
        });        
      }      
    });
  
    tourControl.on('PLAYER_TRANSITION_TO', (data) => {
      if(getMyControlID() === localStorage.controller){
        console.log('tour player transition to');
        socket.emit("TOUR_CONTROL", {
          event: "PLAYER_TRANSITION_TO",
          data,
        });
      }
    });
 
    tourControl.on('PLAYER_TRANSITION_TO_IMMEDIATELY', (data) => {
      if(getMyControlID() === localStorage.controller){
        console.log('tour player transition to immediately');
        socket.emit("TOUR_CONTROL", {
          event: 'PLAYER_TRANSITION_TO_IMMEDIATELY',
          data,
        });
      }
    });

    tourControl.on('THUMBNAIL_PLAY_CLICK', (data) => {
      console.log('thumbnail play click');
      logger.current?.add('THUMBNAIL_PLAY_CLICK', {}, loggedUser.current);
      socket.emit("TOUR_CONTROL", {
        event: 'THUMBNAIL_PLAY_CLICK',
        data: null,
      });
    });

    tourControl.on('SET_ACTIVE_HOTSPOT', (data) => {
      if(getMyControlID() === localStorage.controller){
        console.log('set active hotspot');
        socket.emit("TOUR_CONTROL", {
          event: 'SET_ACTIVE_HOTSPOT',
          data,
        });
      }
    });

    tourControl.on('VIEW_ANGLE_ROTATE_LEFT', (data) => {
      // if(getMyControlID() === localStorage.controller) {
      //   console.log("view angle rotate left");
      //   socket.emit("TOUR_CONTROL", {
      //     event: "VIEW_ANGLE_ROTATE_LEFT",
      //     data,
      //   });
      // }
    });

    tourControl.on('VIEW_ANGLE_ROTATE_UP', (data) => {
      // if(getMyControlID() === localStorage.controller) {
      //   console.log("view angle rotate up");
      //   socket.emit("TOUR_CONTROL", {
      //     event: "VIEW_ANGLE_ROTATE_UP",
      //     data,
      //   });
      // }
    });

    tourControl.on('UPDATE_POSITION_ANGLE', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("update position angle");
        socket.emit("TOUR_CONTROL", {
          event: "UPDATE_POSITION_ANGLE",
          data,
        });
      }
    });

    tourControl.on('BASE_HOTSPOT_HOVER', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("base hotspot hover");
        socket.emit("TOUR_CONTROL", {
          event: "BASE_HOTSPOT_HOVER",
          data,
        });
      }
    });

    tourControl.on('DECK_DROPDOWN_UPDATE', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("deck dropdown update");
        socket.emit("TOUR_CONTROL", {
          event: "DECK_DROPDOWN_UPDATE",
          data,
        });
      }
    });

    tourControl.on('IMAGE_NAVIGATION_UPDATE_IMAGE_OPEN', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("image navigation update image open");
        socket.emit("TOUR_CONTROL", {
          event: "IMAGE_NAVIGATION_UPDATE_IMAGE_OPEN",
          data,
        });
      }
    });

    tourControl.on('UPDATE_IMAGE_NAVIGATION_SCROLL_POSITION', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("update image navigation scroll position");
        socket.emit("TOUR_CONTROL", {
          event: "UPDATE_IMAGE_NAVIGATION_SCROLL_POSITION",
          data,
        });
      }
    });

    tourControl.on('IMAGE_NAVIGATION_UPDATE_SELECTED_IMAGE', (data) => {
      if(getMyControlID() === localStorage.controller) {
        console.log("image navigation update selected image");
        socket.emit("TOUR_CONTROL", {
          event: "IMAGE_NAVIGATION_UPDATE_SELECTED_IMAGE",
          data,
        });
      }
    });

    tourControl.on('SET_ACTIVE_POPUP', (data) => {
      if(getMyControlID() === localStorage.controller) {
        socket.emit("TOUR_CONTROL", {
          event: "SET_ACTIVE_POPUP",
          data,
        });
      }
    });

    tourControl.on('SET_INACTIVE_POPUP', (data) => {
      if(getMyControlID() === localStorage.controller) {
        socket.emit("TOUR_CONTROL", {
          event: "SET_INACTIVE_POPUP",
          data,
        });
      }
    });

    tourControl.on('GENERAL_EVENT', (data) => {
      if(getMyControlID() === localStorage.controller) {
        socket.emit("TOUR_CONTROL", {
          event: "GENERAL_EVENT",
          data,
        });
      }
    });

    // just to make sure it works, toggle lock status twice..
    if (getMyControlID() === localStorage.controller) {
      tourControl.lockControl();
      setLockControl(true);
      setTimeout(() => {
        tourControl.unlockControl();
        setLockControl(false);
      }, 300);
    } else {
      tourControl.unlockControl();
      setLockControl(false);
      setTimeout(() => {
        tourControl.lockControl();
        setLockControl(true);
      }, 300);
    }

    // in client code, replicate the tour action when receiving socket event
    socket.on("TOUR_CONTROL", (data) => {
      if(getMyControlID() !== localStorage.controller) {
        logger.current?.add('RECEIVE_TOUR_CONTROL', data, loggedUser.current);
      }
      console.log(data.event);      
      switch (data.event) {
        case "THUMBNAIL_PLAY_CLICK":{
          console.log('THUMBNAIL_PLAY_CLICK');
          dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.START));
          tourControl.thumbnailPlayClick();

          if(getMyControlID() !== localStorage.controller) {
            tourControl.lockControl();
            setLockControl(true);
          } else {
            tourControl.unlockControl();
            setLockControl(false);
          }

          break;
        }
        case "PLAYER_TRANSITION_TO":
        case "PLAYER_TRANSITION_TO_IMMEDIATELY": {
          if(getMyControlID() !== localStorage.controller) {
            console.log('PLAYER_TRANSITION_TO', data.data);
            tourControl.thumbnailPlayClick();
            tourControl.transitionTo(data.data);
          }
          break;
        }
        case "PLAYER_START_AUTO_SPIN":
          if(getMyControlID() !== localStorage.controller)
            tourControl.startAutoSpin(data?.data);
          break;
        case "PLAYER_STOP_AUTO_SPIN":
          if(getMyControlID() !== localStorage.controller)
            tourControl.stopAutoSpin();
          break;
        case "SET_ACTIVE_HOTSPOT":
          if(getMyControlID() !== localStorage.controller) {
            tourControl.setActiveHotspot(data.data);
          }
          break;
        case "VIEW_ANGLE_ROTATE_LEFT":
          if(userState.user.role !== localStorage.controller)
            tourControl.viewAngleRotateLeft(data.data);
          break;
        case "VIEW_ANGLE_ROTATE_UP":
          if(getMyControlID() !== localStorage.controller)
            tourControl.viewAngleRotateUp(data.data);
          break;
        case "UPDATE_POSITION_ANGLE":
          if(getMyControlID() !== localStorage.controller)
            tourControl.viewAngleUpdatePosition(data.data);
          break;
        case "BASE_HOTSPOT_HOVER":
          if(getMyControlID() !== localStorage.controller)
            tourControl.baseHotspotHover(data.data);
          break;
        case "DECK_DROPDOWN_UPDATE":
          if(getMyControlID() !== localStorage.controller)
            tourControl.deckDropdownUpdate(data.data);
          break;
        case "IMAGE_NAVIGATION_UPDATE_IMAGE_OPEN":
          if(getMyControlID() !== localStorage.controller)
            tourControl.toggleImageNavigation(data.data);
          break;
        case "UPDATE_IMAGE_NAVIGATION_SCROLL_POSITION":
          if(getMyControlID() !== localStorage.controller)
            tourControl.updateImageNavigationScroll(data.data);
          break;
        case "IMAGE_NAVIGATION_UPDATE_SELECTED_IMAGE":
          if(getMyControlID() !== localStorage.controller)
            tourControl.updateSelectedImageNavigation(data.data);
          break;
        case "SET_ACTIVE_POPUP":
          if(getMyControlID() !== localStorage.controller)
            tourControl.setActivePopup(data.data.uuid);
          break;
        case "SET_INACTIVE_POPUP":
          if(getMyControlID() !== localStorage.controller)
            tourControl.setInactivePopup(data.data.uuid);
          break;
        case "GENERAL_EVENT":
          if(getMyControlID() !== localStorage.controller)
            tourControl.sendEvent(data.data?.name, data.data?.payload);
          break;
        default:
          break;
      }
    });

    
  }

  useEvent('TRANSFER_CONTROL_IN_GROUP', (data : any) => {
    console.log('data', data);
    // @ts-ignore
    const tourControl = window.tourControl
    localStorage.setItem("controller", data.controller);
    setAlert({
      isShow: true,
      msg: (() => {
        if (!data.controller.includes('-')) return `The tour control has been transferred to ${data.controller}`;
        const parts = data.controller.split('-');
        if (parts.length >= 2 && !isNaN(parts[0])) {
          return `The tour control has been transferred to ${parts[1]}`;
        }
        return `The tour control has been transferred to other person`;
      })(),
    })
    setTimeout(() => {
      setAlert({
        isShow: false,
        msg: ''
      })
    }, 3000);
    if (data.controller === getMyControlID()) {
      console.log('unlock Control here');
      tourControl.unlockControl();
      setLockControl(false);
    } else {
      tourControl.lockControl();
      setLockControl(true);
    }
  }, []);

  const switchTour = (tour) => {
    // setTourToken(tour.token); // note: can be remove, it will be set when receive socket event
    const embedUrl = `${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${tour.token}`;
    virtualTourState.socket.emit("SWITCH_TOUR", {
      url: embedUrl
    })
  }

  const handleTransferControl = () => {    
    setShowTransferModal({ show: false, claimBack: false });
  }

  return (
    <div className="right-panel d-flex flex-column">
      <div className="main-header d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h1 className="title">
            {tourState?.tour?.name || ''}
          </h1>
          <ChatAndSwitchBtnPanel
            virtualTourState={virtualTourState}
            curTour={tourState?.tour}
            onSwitchTour={tour => switchTour(tour)}
            onArrowBtnClick={() => setShowTransferModal({ show: true, claimBack: false })}
            onClaimBackTourControl={() => setShowTransferModal({ show: true, claimBack: true })}
            // isCalling={isCalling}
            // disableVoiceCall={disableVoiceCall}
            onLeaveVideoCall={onLeaveVideoCall}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div />
          <div>
            <WhoInControl />
            <OnlineOfflineNotice />
          </div>
        </div>
      </div>
      <div style={{position: 'relative', flex: '1'}}>
        <iframe 
          title="virtual-tour"
          key={tourState?.tourToken}
          id={`tour-${tourState?.tourToken}`.replace(/\//g, '')} 
          ref={iframeRef} 
          src={tourState?.url} 
          width="100%" 
          height="100%" 
          style={{border: 'none'}}
          allowFullScreen
          allow="autoplay; encrypted-media"
        />
        {virtualTourState.eventType === CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.PAUSE && (
          <PauseScreen>
            <h2>Are you sure you want to stop?</h2>
            <div className="pause-btn"></div>
            <p>This will disconnect your guided tour session with your <br /> BURGESS {CONSTANTS.ROLES.broker}.</p>
            <p>You will be sent a link to the virtual tour and your <br />recorded session.</p>
          </PauseScreen>
        )}
        {lockControl && (
          <div className="lock-overlay">
            <img src={svgBtnLock} alt="" />
          </div>
        )}
      </div>
      {/* <ActionPanel curPage={curPage} setPage={(selectedOne: string) => {onClickStart(selectedOne)}} /> */}
      {/* <BtnPanel controller={virtualTourState.controller} handleEvent={(eventType: string) => {handleEvent(eventType)}}/> */}
      {/* <OptionModal isShow={showOptionModal} hideModal={() => setShowOptionModal(false)} /> */}
      <TransferModal
        isGroup
        shouldClaimBack={showTransferModal.claimBack}
        isShow={showTransferModal.show}
        hideModal={() => handleTransferControl()}
      />
      <Alert variant="info" show={alert.isShow}>{alert.msg}</Alert>
      <ChatSlideInPanel />
    </div>
  );
};

const PauseScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  background-color: rgba(0,0,0, 0.8);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  color: white;

  .pause-btn {
    box-sizing: border-box;
    height: 34px;
    border-color: transparent transparent transparent #FFF;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: double;
    border-width: 0px 0 0px 30px;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 3rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
`
export default MainPanel;
