import React from 'react';
import { Link, withRouter } from "react-router-dom";
import RequestHelper from '../../../utils/Request.Utils';
import groupSessionSvg from '../../../assets/images/btn_group_request.svg';
import CONFIG from '../../../config';

type Props = {
  data: {
    name: string,
    type?: string,
    info?: {
      length: string,
      year: number,
      content: string
    },
    token: string,
    imgUrl: string
  },
  hasGroupBtn: boolean,
  takeTheTourText?: string,
  tourGroup?: number,
}

function RequestIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.18 79.18" width="40" height="40" >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle cx="39.59" cy="39.59" r="39.59" className="cls-1" style={{ fill: 'var(--primary-color)' }}></circle>
          <path
            d="M21.86 37.85v-.17-.12-.11a8.74 8.74 0 01.1-1.28L22 36h-.17a7.63 7.63 0 119.66-8.58v.17h1.42v-.25a9 9 0 10-12.69 9.48 13.11 13.11 0 00-9.42 12.57v2.91a.7.7 0 001.4 0v-2.91a11.75 11.75 0 019.49-11.51z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
          <path
            d="M40.79 37.46A9 9 0 1028 45.65a13.15 13.15 0 00-9.35 12.57v2.91a.7.7 0 101.4 0v-2.91a11.73 11.73 0 0119.33-8.93.76.76 0 00.46.16.71.71 0 00.53-.24.7.7 0 00-.08-1 13 13 0 00-4.74-2.57 9 9 0 005.24-8.18zm-1.4 0a7.63 7.63 0 11-7.63-7.63 7.64 7.64 0 017.63 7.63zM49.47 36.75v3.44a.69.69 0 00.35.59.72.72 0 00.32.08.7.7 0 00.37-.11l6.09-4h3.24a6 6 0 006-6v-4.1a6 6 0 00-6-6h-9.65a6 6 0 00-6 6v4.11a6 6 0 005.28 5.99zm.68-1.31a4.7 4.7 0 01-4.65-4.68v-4.11A4.7 4.7 0 0150.19 22h9.65a4.7 4.7 0 014.69 4.69v4.11a4.7 4.7 0 01-4.69 4.69H56.4a.7.7 0 00-.37.11L50.82 39v-2.88a.69.69 0 00-.67-.68z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
          <path
            d="M51.45 28.8a.9.9 0 10-.9.9.9.9 0 00.9-.9zM55 29.7a.9.9 0 10-.9-.9.9.9 0 00.9.9zM59.48 29.7a.9.9 0 10-.9-.9.9.9 0 00.9.9zM55.09 50.5a.72.72 0 00.48-.2.61.61 0 00.21-.47.64.64 0 00-.2-.5l-5.23-5.23a.66.66 0 00-.74-.15.91.91 0 00-.23.15l-5.22 5.22a.67.67 0 00-.2.48.7.7 0 00.2.49.67.67 0 001 0l4.06-4.06v15.65a.69.69 0 101.37 0V46.23l4.06 4.06a.73.73 0 00.44.21zM65.78 56.18a.68.68 0 00-.48-.18.67.67 0 00-.5.2l-4.06 4.06V44.58a.69.69 0 00-1.37 0v15.65l-4.06-4.06a.68.68 0 00-.49-.2.69.69 0 00-.68.69.67.67 0 00.2.48l5.23 5.23a.64.64 0 00.22.14.58.58 0 00.26.06.71.71 0 00.49-.21l5.22-5.22a.65.65 0 00.21-.48.66.66 0 00-.19-.48z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
        </g>
      </g>
    </svg>
  );
}

const TourCard = ({ data, hasGroupBtn, takeTheTourText = 'Take the Tour', tourGroup }: Props) => {

  const requestGroupTour = async () => {
    const res = await RequestHelper.post('/tour-session/request-group', {
      tourUrl: `${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${data.token}`,
      tourGroup
    });
    console.log('res', res);
    if (res.data && res.data.data && res.data.data.sessionId) {
      window.location.href = `/group/virtual-tour/${res.data.data.sessionId}/${res.data.data.slug}`;
    }
  }

  return (
    <div className="tour-card">
      <img src={data.imgUrl} style={{width: '100%', objectFit: 'cover'}} alt={data.name} />
      <h1>{data.name}</h1>
      {/* <p>{data.type}</p>
      <p>{data.info.length}, {data.info.year}, {data.info.content}</p> */}
      {takeTheTourText &&
      <a>
        <Link to={`/tour/view/${data.token}`}>
          {takeTheTourText} {'>'}
        </Link>
      </a>}
      {hasGroupBtn && (
        <div className="group-session-request-button" onClick={requestGroupTour}>
          {/* <img src={groupSessionSvg} alt="Create group session" /> */}
          <RequestIcon />
        </div>
      )}
    </div>
  )
}

export default TourCard;