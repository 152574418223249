import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CONFIG from './config';
import App from './App';
import store from './store'
import * as serviceWorker from './serviceWorker';
// import RequestHelper from './utils/Request.Utils';

// if (localStorage.token)
//   RequestHelper.setToken(localStorage.token)

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

const supportMultiSites = [
  'https://api-guide.theatro360.com/api',
  'http://localhost:8080/api',
];

if (supportMultiSites.includes(CONFIG['API_URL'])) {
  fetch(CONFIG['API_URL'] + '/users/site-info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subdomain: window.location.hostname.split('.')[0],
    })
  }).then(res => res.json())
  .then(json => {
    console.log('json', json);
    const data = !!json && !!json.data ? json.data : {};
    const { subDomain, image, backgroundColor, branchColor, playbooks } = data;
    if (!subDomain) return startApp();
    else {
      CONFIG['API_URL'] = CONFIG['API_URL'].replace('http://', `http://${subDomain}.`).replace('https://', `https://${subDomain}.`);
      CONFIG['SOCKET_HOSTNAME'] = subDomain + '.' + CONFIG['SOCKET_HOSTNAME'];
      CONFIG['IMAGE'] = !!image ? image : CONFIG['IMAGE'];
      CONFIG['PLAYBOOKS'] = playbooks;
      CONFIG['SUBDOMAIN'] = subDomain;
      if (branchColor) document.documentElement.style.setProperty('--primary-color', branchColor);
      if (backgroundColor) document.documentElement.style.setProperty('--background-color', backgroundColor);
      startApp();
    }
    
  })
  .catch(err => {
    console.log('err', err);
    startApp();
  })
} else {
  startApp();
}


serviceWorker.unregister();
