import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultAvatarSvg from '../../../assets/images/default-avatar-filled.svg';
import './ModalListUser.scss';
import _ from 'lodash';

interface Props {
  open: boolean,
  onClose(): void,
  list: Array<{ name: string, avatar: string, email: string }>,
  tourName: string,
  [additionProps: string]: any,
}

const ModalListUser = (props : Props) => {
  const cleanList = _.uniqWith(props.list.filter(val => !!val.name && !!val.email).map(val => ({ ...val, deletedAt: undefined })), _.isEqual);
  return (
    <CustomModal
      show={props.open}
      onHide={props.onClose}
      centered
      className="reset-password-modal"
    >
      <Modal.Body className="modal-list-user">
        <h3>{props.tourName}</h3>
        {cleanList.map((val, i) => (
          <div
            key={'user-row'+i}
            className="user-row"
          >
            <img src={!val.avatar ? DefaultAvatarSvg : val.avatar} alt="" />
            <div>
              <p><b>Name:</b> {val.name}</p>
              <p><b>Email:</b> {val.email}</p>
            </div>
          </div>
        ))}
        <FontAwesomeIcon
          icon={faTimes}
          color="white"
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer'
          }}
          onClick={props.onClose}
        />
      </Modal.Body>
    </CustomModal>
  );
};

const CustomModal = styled(Modal)`
  .form-group {
    margin-bottom: 0.8rem;

    label {
      margin: 0;
      color: white;
      opacity: 0.8;
      font-size: 0.8rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
      border-bottom: 1px solid white;
      border-radius: 0;
      padding: 0;
      color: white;
      height: 1.5rem;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  Button {
    background: white;
    width: 100%;
    color: orange;
    margin-top: 1rem;
    border: 1rem;
    height: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .signin-btn {
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`

export default ModalListUser;
