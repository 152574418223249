import React, { useState } from 'react';
import StopSessionModal from './StopSessionModal';

const StopIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.234"
      height="30.233"
      viewBox="0 0 30.234 30.233"
      {...props}
    >
      <g transform="translate(0 -.008)">
        <path
          d="M290.244 209.9h-11.967a.945.945 0 010-1.89h11.967a.945.945 0 010 1.89zm0 0"
          data-name="Path 45"
          transform="translate(-260.956 -195.718)"
        ></path>
        <path
          d="M400.948 139.31a.945.945 0 01-.668-1.614l4.056-4.056-4.056-4.056a.945.945 0 111.337-1.336l4.724 4.724a.945.945 0 010 1.336l-4.724 4.724a.939.939 0 01-.669.278zm0 0"
          data-name="Path 46"
          transform="translate(-376.384 -120.406)"
        ></path>
        <path
          d="M10.078 30.241a2.6 2.6 0 01-.781-.117L1.716 27.6A2.543 2.543 0 010 25.2V2.527A2.522 2.522 0 012.52.008a2.6 2.6 0 01.78.117l7.581 2.526a2.542 2.542 0 011.716 2.4v22.671a2.522 2.522 0 01-2.519 2.519zM2.52 1.9a.632.632 0 00-.63.63V25.2a.655.655 0 00.437.607l7.546 2.514a.684.684 0 00.206.028.632.632 0 00.63-.63V5.047a.655.655 0 00-.437-.607L2.725 1.925A.684.684 0 002.52 1.9zm0 0"
          data-name="Path 47"
        ></path>
        <path
          d="M44.3 10.086a.945.945 0 01-.945-.945V3.472a1.577 1.577 0 00-1.57-1.572H27.613a.945.945 0 110-1.89h14.172a3.467 3.467 0 013.464 3.464v5.667a.945.945 0 01-.949.945zm0 0"
          data-name="Path 48"
          transform="translate(-25.093)"
        ></path>
        <path
          d="M187.316 287.418h-5.039a.945.945 0 110-1.89h5.039a1.577 1.577 0 001.574-1.575v-5.669a.945.945 0 011.89 0v5.669a3.467 3.467 0 01-3.464 3.465zm0 0"
          data-name="Path 49"
          transform="translate(-170.624 -260.956)"
        ></path>
      </g>
    </svg>
  );
}

const StopSessionIcon = () => {
  const [showStopSessionModal, setShowStopSessionModal] = useState(false);
  return (
    <>
      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => setShowStopSessionModal(true)}>
        <span style={{ color: 'white', fontWeight: 600 }}>End Session</span>
        {/* <StopIcon
          style={{ height: 22, marginRight: 7, marginLeft: 5, fill: 'red', cursor: 'pointer' }}
        /> */}
      </div>
      
      <StopSessionModal
        open={showStopSessionModal}
        onClose={() =>  setShowStopSessionModal(false)}
      />
    </>
  );
};

export default StopSessionIcon;
