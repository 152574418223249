import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Form } from 'react-bootstrap';
import { useGlobalState } from 'js-events-listener/react';
import * as CONSTANTS from '../../constants';
import RequestHelper from '../../utils/Request.Utils';
import { useSelector } from 'react-redux';

type Props = {
  curTour: any,
  isDisable: boolean,
  changeTour: Function
}

const TourDropDown = ({ curTour, isDisable, changeTour }: Props) => {
  const { virtualTourState } = useSelector((state: any) => ({
    virtualTourState: state.virtualTour,
  }));

  // console.log('TourDropDown virtualTourState', virtualTourState);

  // const tourList = CONSTANTS.HOME_TOURS;
  const [allTours, setAllTours] = useGlobalState([], 'group-all-tours');
  const [keyword, setKeyword] = useState('');

  const displayTours = useMemo(() => {
    if (!allTours?.length) return [];

    let result = allTours.sort((a, b) => a.tourName > b.tourName ? 1 : -1);
    if (keyword) {
      result = result.filter(i => String(i.tourName).toLowerCase().includes(keyword.toLowerCase()))
    }
    return result;
  }, [allTours, keyword])

  const url2Token = (tourUrl) => {
    const parts = tourUrl.split('/');
    return parts[parts.length - 1];
  };

  const onChangeKeyword = useCallback((e) => {
    setKeyword(e.target.value);
  }, []);

  const makeSureGetAllTours = useCallback(async () => {
    if (allTours.length !== 0) return;
    if (!virtualTourState.tourSession) return;
    const { tourGroup } = virtualTourState.tourSession;
    const res = tourGroup === 0 ? await RequestHelper.get('/tours/list', {})
      : await RequestHelper.get('/tours/group/' + tourGroup, {});
    if (res.data?.success) {
      const allGroupTours = tourGroup === 0 ? res.data?.data?.list.map(val => ({
        ...val,
        id: val.ID,
        tourToken: (() => {
          const parts = val.tourUrl.split('/');
          return parts[parts.length - 1];
        })(),
      })) : res.data?.data?.tours.map(val => ({
        ...val,
        id: val.ID,
        tourToken: (() => {
          const parts = val.tourUrl.split('/');
          return parts[parts.length - 1];
        })(),
      }));
      setAllTours(allGroupTours);
    }
  }, [allTours, setAllTours]);

  useEffect(() => {
    setTimeout(() => {
      makeSureGetAllTours();
    }, 2000);
    setTimeout(() => {
      makeSureGetAllTours();
    }, 5000);
  }, []);

  return (
    <CustomDropdown className={`${!isDisable ? '' : 'btn-disable'}`}>
      <Dropdown
        drop={"left"}
        style={{ zIndex: 100000 }}
      >
        <DropdownToggle variant="success" id="dropdown-basic" className="p-0">
          <Bar /><Bar /><Bar />
        </DropdownToggle>

        <DropdownMenu style={{ height: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
          <Title className="mb-4">Select tour</Title>
          <Form>
            <Form.Control
              value={keyword}
              placeholder="Search..."
              onChange={onChangeKeyword}
            />
          </Form>
          <div style={{ marginRight: 10, marginTop: 10 }}>
            {displayTours.map((val, nIndex) => {
              const item = {
                name: val.tourName,
                token: url2Token(val.tourUrl),
                imgUrl: val.tourThumbnail,
              };
              return (
                <DropdownItem
                  className={curTour?.name === item.name ? 'active' : ''}
                  onClick={() => changeTour(item)}
                  key={nIndex}
                >
                  {item.name}
                </DropdownItem>
              )
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
    </CustomDropdown>

  )
}

const CustomDropdown = styled.div`

`
const Title = styled.h4`
  line-height: 1;
  font-size: 1.8rem;
  font-weight: 400;
`
const DropdownToggle = styled(Dropdown.Toggle)`
  background: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  &:hover {
    background
  }
  &:before {
    display: none !important;
  }
  padding-top: 0;
`
const Bar = styled.div`
  width: 26px;
  height: 2px;
  background: var(--primary-color);
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0
  }  
`
const DropdownMenu = styled(Dropdown.Menu)`
  border: none;
  padding: 2rem;
  position: relative;
  border-top-right-radius: 0;
  right: 15px !important;
  width: 295px;

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 15px solid transparent;
    border-left: 10px solid white;
    top: 0;
    right: -10px;
  }

  .dropdown-item {
    padding-left: 0;
    padding-right: 0;
    background-color: transaprent !important;
    &: hover {
      color: #ff0050;      
    }
  }
`

const DropdownItem = styled(Dropdown.Item)`
  font-size: 1.2rem;
  padding: 0;
  line-height: 1.7rem;
  text-transform: uppercase;
  white-space: pre-line;
  margin-bottom: 0.5rem;

  &.active {
    background: none;
    color: #ff0050
  }

  &:active, &:hover {
    background: none;
  }
`;

export default TourDropDown;
