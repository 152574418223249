import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RequestHelper from '../../../utils/Request.Utils';
import NavMenu from '../../../sharedComponents/NavMenu';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import uploadIcon from '../../../assets/images/upload-icon.png';
import moment, { Moment } from 'moment';
import './UserAddNameModal.scss';
import { getTourToken } from '../../../utils/Common.Utils';
import DefaultAvatarSvg from '../../../assets/images/default-avatar-filled.svg';

interface Props {
  open: boolean,
  onClose(): void,
  onAddInfo(name: string, email: string, avatar: string): void,
  onStillBeKicked?(): any,
  [additionProps: string]: any,
}

const UserAddNameModal = (props: Props) => {
  const { id } = useParams();
  const [name, setName] = useState(localStorage.getItem('group_tour_name') || '');
  const [email, setEmail] = useState(localStorage.getItem('group_tour_email') || '');
  const [avatar, setAvatar] = useState(localStorage.getItem('group_tour_avatar') || '');
  const [isKicked, setIsKicked] = useState(false);
  const [wallpaper, setWallpaper] = useState('');
  const [tourData, setTourData] = useState();
  const [scheduleTime, setScheduleTime] = useState<Moment>();

  const listUser = useRef([]);

  const getUsers = async () => {
    const res = await RequestHelper.get(`/tour-session/${id}/members`, {});
    // console.log('res.data', res.data);
    if (res.data.success) {
      listUser.current = res.data.data;
      // console.log('listUser.current', listUser.current)
    }
  };

  useEffect(() => {
    const savedName = localStorage.getItem('group_tour_name');
    const kickedName = localStorage.getItem('kick_user_group_' + id);
    if (!savedName || !kickedName || savedName !== kickedName) return;
    const timeKick = localStorage.getItem('time_kick_user_group_' + id);
    const isAfterTwoHours = new Date().getTime() - Number(timeKick) > 1000 * 2 * 3600;
    if (!isAfterTwoHours) {
      setIsKicked(true);
      props.onStillBeKicked && props.onStillBeKicked();
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const updateWallpaper = useCallback(async () => {
    const tour_session_res = await RequestHelper.get(`/tour-session/${id}`, {});
    if (!tour_session_res.data.success) return;
    setTourData(tour_session_res.data.data);
    setWallpaper(tour_session_res.data.data.tourThumbnail);

    if (tour_session_res.data.data.scheduleTime) {
      setScheduleTime(moment(tour_session_res.data.data.scheduleTime));
      if (moment(tour_session_res.data.data.scheduleTime).isBefore(new Date())) {
        clearInterval(intervalRef.current);
      }
    }
  }, [id]);

  const onFocus = useCallback(() => {
    if (!!scheduleTime && scheduleTime.isAfter(new Date())) {
      updateWallpaper();
      intervalRef.current = setInterval(() => {
        updateWallpaper();
      }, 10000)
    }
  }, [scheduleTime, updateWallpaper])

  const onBlur = useCallback(() => {
    clearInterval(intervalRef.current)
  }, [])

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      clearInterval(intervalRef.current)
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    }
  }, [onFocus, onBlur])

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      updateWallpaper();
    }, 500);
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, []);

  const onAddInfo = () => {
    let systemPickName = name;
    if (!systemPickName || !email) {
      return alert('Please fill all the forms');
    }
    let i = 1;
    while (!!listUser.current.find(val => val.name === systemPickName)) {
      i++;
      systemPickName = `${systemPickName} (${i})`;
      // return alert('Please choose a different name, someone is already using the name: ' + name);
    }

    console.log('systemPickName', systemPickName);

    localStorage.setItem('group_tour_name', systemPickName);
    localStorage.setItem('group_tour_email', email);
    localStorage.setItem('group_tour_avatar', avatar);
    props.onAddInfo(systemPickName, email, avatar);
  }

  const inputFileRef = useRef(null);

  const onUploadAvatar = async e => {
    if (Array.from(e.target.files).length === 0) return;
    const file = e.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      alert('Maximum size is 10MB, please try again');
      return;
    }
    const upload_res = await RequestHelper.upload(file);
    if (upload_res.data.success && !!upload_res.data.url) {
      setAvatar(upload_res.data.url);
    }
  }

  if (isKicked) return null;

  const tourToken = getTourToken(tourData?.tourUrl);
  const tourViewUrl = `${window.location.protocol}//${window.location.host}/tour/view/${tourToken}`;

  return (
    <CustomModal
      show={props.open}
      onHide={props.onClose}
      centered
      className="reset-password-modal group-tour-enter-name"
      backdrop="static"
      style={{
        background: `url("${wallpaper}") center center /cover no-repeat`,
      }}
    >
      <Modal.Body className="group-user-add-name-modal">
        <div className="group-user-add-name-modal dummy-nav-menu" style={{ display: props.open && wallpaper ? 'block' : 'none' }}>
          <NavMenu />
        </div>
        {!!scheduleTime && scheduleTime.isBefore(new Date()) ? (
          <div className="container-fluid">
            <h4>Welcome to our Shared Tour</h4>
            <p>Please add your information</p>
            <div className="row">
              <div className="col-8">
                <label>
                  <p className="label">Enter your Name</p>
                  <input value={name} onChange={e => setName(e.target.value)} />
                </label>
                <label>
                  <p className="label">and Email</p>
                  <input className="mt-2" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
              </div>
              <div className="col-4">
                <p className="label" style={{ textAlign: 'center' }}>Avatar</p>
                {false && !avatar ? (
                  <div className="avatar-frame middle-row" onClick={() => !!inputFileRef.current && inputFileRef.current.click()}>
                    <img src={uploadIcon} alt="Upload" style={{ width: 30, height: 30, marginBottom: 5, }} />
                    <span>Upload</span>
                    <input onChange={onUploadAvatar} ref={inputFileRef} type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} />
                  </div>
                ) : (
                  <>
                    {/* HARD CODE DEFAULT AVATAR BECAUSE UPLOAD AVATAR IS BROKEN */}
                    <div className="avatar-frame" style={{ border: 'none' }}>
                      <img
                        alt="Avatar"
                        src={DefaultAvatarSvg}
                      />
                    </div>
                    {/* <p className="remove-avatar" onClick={() => setAvatar('')}>× Remove</p> */}
                  </>
                )}
              </div>
            </div>
            <button className="btn-ok" onClick={onAddInfo}>OK</button>
          </div>
        ) : (
        <div>
          <p className="text-center">The session has not started yet. If you wish to change the time please email your host</p>
          {tourData?.broker?.email && (
            <p className="text-center"><a href={`mailto:${tourData?.broker?.email}`}>{tourData?.broker?.email}</a></p>
          )}
          {!tourData?.isSecretTour && (
            <>
              <p className="text-center">Meanwhile please feel free to explore the virtual tour here:</p>
              <p className="text-center"><a href={tourViewUrl}>{tourViewUrl}</a></p>
            </>
          )}
        </div>
        )}

      </Modal.Body>
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .form-group {
    margin-bottom: 0.8rem;

    label {
      margin: 0;
      color: white;
      opacity: 0.8;
      font-size: 0.8rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
      border-bottom: 1px solid white;
      border-radius: 0;
      padding: 0;
      color: white;
      height: 1.5rem;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  Button {
    background: white;
    width: 100%;
    color: orange;
    margin-top: 1rem;
    border: 1rem;
    height: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .signin-btn {
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`

export default UserAddNameModal;
