import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconChat from '../../../assets/images/new-icons/chat.svg';
import IconGroup from '../../../assets/images/new-icons/group.svg';
import IconShare from '../../../assets/images/new-icons/share.svg';
import IconSoundOff from '../../../assets/images/new-icons/sound_off.svg';
import IconSound from '../../../assets/images/new-icons/sound.svg';
import IconVideo from '../../../assets/images/new-icons/video.svg';
import IconVideoOff from '../../../assets/images/new-icons/video_off.svg';
import IconHangVideoCall from '../../../assets/images/new-icons/hang.svg';

import IconLogoutSvg from '../../../assets/images/signs.svg';
import DefaultAvatarSvg from '../../../assets/images/default-avatar-filled.svg';
import TourDropDown from "../../../sharedComponents/TourDropDown";
import * as Constants from "../../../constants";
import ArrowSVG from '../../../assets/images/switchcontrol_1.svg';
import GlobalEvent, { useGlobalState, useEvent } from 'js-events-listener/react';
import VideoChatPopup from '../ChattingPanel/VideoChatPopup';
import './ChatAndSwitchBtnPanel.scss';
import { DialogNames } from '../../../store/dialog/types';
import { useGlobalEvent } from '../../../utils/Common.Utils';

import GroupNameModal from './GroupNameModal'


declare var Twilio;
let _timeout;



const ChatAndSwitchBtnPanel = ({
  onSwitchTour, curTour, virtualTourState, onArrowBtnClick, onLeaveVideoCall, onClaimBackTourControl
}) => {
  const { dialogState, userState } = useSelector((state: any) => ({
    dialogState: state.dialog,
    userState: state.user,
  }));
  const [rerender, setRerender] = useState(Math.random());
  const [videoDisplayMode, setVideoDisplayMode] = useState('');
  const [groupNameDataModal, setGroupNameDataModal] = useGlobalState({ open: false, firstTime: false }, 'group-name-modal');
  const [showDotNotiChat, setShowDotNotiChat] = useState(false);
  const [groupCallVideoEnable, setGroupCallVideoEnable] = useState(true);
  const [groupCallSoundEnable, setGroupCallSoundEnable] = useState(true);
  const [userAddNameModalData] = useGlobalState({
    open: false,
    name: '',
    email: '',
    avatar: '',
  }, 'guest-name');

  const getMyControlID = useCallback(() => {
    if (userAddNameModalData.name) return userAddNameModalData.name;
    return `${userState.user.ID}-${userState.user.name}`;
  }, [userAddNameModalData, userState.user]);

  const getMyName = useCallback(() => {
    if (userAddNameModalData.name) return userAddNameModalData.name;
    return userState.user.name;
  }, [userAddNameModalData, userState]);

  const getMyAvatar = useCallback(() => {
    if (userAddNameModalData.avatar) return userAddNameModalData.avatar || DefaultAvatarSvg;
    return userState.user.avatar || DefaultAvatarSvg;
  }, [userAddNameModalData, userState]);

  const dispatch = useDispatch();
  const videoChatRef = useRef(null);
  const chatSlidedRef = useRef(false);

  useEffect(() => {
    GlobalEvent.on('ACCEPT_CALL', () => {
      clearTimeout(_timeout);
      _timeout = setTimeout(() => {
        console.log('ACCEPT_CALL');
        joinRoomChat();
        // onVideoConnected();
      }, 500);

    });
  }, [])

  useEvent('CONTROLLER_READY', () => {
    setTimeout(() => {
      setRerender(Math.random());
    }, 2000);
  }, []);

  useEvent('VIDEO_GROUP_SOUND_UPDATE', (toggle) => {
    setGroupCallSoundEnable(Boolean(toggle));
  }, []);
  useEvent('VIDEO_GROUP_VIDEO_UPDATE', (toggle) => {
    setGroupCallVideoEnable(Boolean(toggle));
  }, []);

  useEffect(() => {
    if (dialogState.name !== DialogNames.VOICE_CHATTING_DIALOG) return;

    switch (dialogState.action) {
      case Constants.VoiceCallActions.call:
        // Twilio.Device.connect({name: })
        break;
      case Constants.VoiceCallActions.accept: {
        if (!virtualTourState.twilioConnection) return;
        virtualTourState.twilioConnection.accept();

        virtualTourState.socket.emit("VOICE_READY", {
          deviceName: virtualTourState.twilioConnection.message.name
        })
        break;
      }
      case Constants.VoiceCallActions.hangup:
        Twilio.Device.disconnectAll();
        break;
      case Constants.VoiceCallActions.decline:
        Twilio.Device.disconnectAll();
        break;
      default:
        break;
    }
  }, [dialogState, virtualTourState.socket, virtualTourState.twilioConnection]) // eslint-disable-line

  const joinRoomChat = () => {
    videoChatRef.current.init();
  };

  const onChangeDisplayMode = (newDisplayMode) => {
    setVideoDisplayMode(newDisplayMode);
  }

  const onVideoIconClick = () => {
    if (videoDisplayMode !== 'HIDDEN') {
      GlobalEvent.emit('DISCONNECT_VIDEO_CALL');
      return;
    }
    // @ts-ignore
    window.audioDisconnectShowModal = false;
    // Twilio.Device.disconnectAll();
    if (virtualTourState.socket) {
      const socket = virtualTourState.socket;
      socket.emit("VIDEO_CALL", {
        name: userState.user.name,
      });
    }
    joinRoomChat();
    // onVideoConnected();
  }

  const onChatIconClick = () => {
    GlobalEvent.emit('SLIDE_CHAT_PANEL');
  }

  useGlobalEvent('UPDATE_CHAT_DISPLAY_PANEL', (show) => {
    chatSlidedRef.current = show;
    if (chatSlidedRef.current && showDotNotiChat) {
      setShowDotNotiChat(false);
    }
  }, [showDotNotiChat]);

  useGlobalEvent('CHAT_RECEIVED_MESSAGES', () => {
    if (chatSlidedRef.current) return;
    setShowDotNotiChat(true);
  }, []);

  const onAddUsersClick = () => {
    setGroupNameDataModal({ open: true, firstTime: false });
  };

  const shouldShowAddUserBtn = !virtualTourState.tourSession || !userState.token ? false : userState.user.ID === virtualTourState.tourSession?.brokerId;
  const isMainBroker = !virtualTourState.tourSession ? false : virtualTourState.tourSession.broker.name === getMyName();

  const toggleSound = () => GlobalEvent.emit('VIDEO_GROUP_SOUND_TOGGLE');
  const toggleVideo = () => GlobalEvent.emit('VIDEO_GROUP_VIDEO_TOGGLE');

  return (
    <div className="d-flex flex-row chat-and-switch-btn-panel-group">
      <div className="video-call">
        {videoDisplayMode === 'HIDDEN' ? (
          <img src={IconVideo} style={{ height: 30 }} onClick={onVideoIconClick} alt="" />
        ) : (
          <>
            <img src={groupCallVideoEnable ? IconVideo : IconVideoOff} style={{ height: 30 }} onClick={toggleVideo} alt="" />
            <img src={groupCallSoundEnable ? IconSound : IconSoundOff} style={{ height: 30 }} onClick={toggleSound} alt="" />
            {/* <img src={IconHangVideoCall} style={{ height: 30, width: 25 }} onClick={onVideoIconClick} alt="" /> */}
          </>
        )}
      </div>
      {shouldShowAddUserBtn &&
        <div className="add-users">
          <img src={IconGroup} style={{ height: 27 }} onClick={onAddUsersClick} alt="" />
        </div>}
      <div className="chat">
        <img src={IconChat} style={{ height: 30, transform: 'translateX(2px)' }} onClick={onChatIconClick} alt="" />
        {showDotNotiChat && (
          <div className="dot-notification" />
        )}
      </div>
      {getMyControlID() === localStorage.controller && (
        <>
          <img src={IconShare} style={{ height: 30, marginRight: 7, }} onClick={onArrowBtnClick} alt="" />
          <div className="d-flex flex-column">
            <TourDropDown
              curTour={curTour}
              changeTour={onSwitchTour}
              isDisable={false}
            />
          </div>
        </>
      )}
      {getMyControlID() !== localStorage.controller && isMainBroker  && (
        <img src={IconShare} style={{ height: 30,  }} onClick={onClaimBackTourControl} alt="" />
      )}

      <VideoChatPopup
        ref={videoChatRef}
        yourName={getMyName()}
        yourAvatar={getMyAvatar()}
        otherName={""}
        room={'video-group-chat-' + virtualTourState.tourSession?.ID}
        tourSession={virtualTourState.tourSession}
        onLeave={onLeaveVideoCall}
        onChangeDisplayMode={onChangeDisplayMode}
        virtualTourState={virtualTourState}
      />
      <GroupNameModal
        open={groupNameDataModal.open}
        onClose={() => {
          if (groupNameDataModal.firstTime) {
            GlobalEvent.emit('CONTROLLER_READY');
          }
          setGroupNameDataModal({ open: false, firstTime: false })
        }}
      />
    </div>
  );
};

export default ChatAndSwitchBtnPanel;