import React, { useEffect, useState } from 'react';
import TourCard from '../TourCard';
import RequestHelper from '../../../utils/Request.Utils';
import { getTourToken } from '../../../utils/Common.Utils';

const TourList = ({ hasGroupBtn, takeTheTourText = 'Take the tour' }) => {
  const [list, setList] = useState([]);
  const [order, setOrder] = useState([]);

  const url2Token = (tourUrl) => {
    return getTourToken(tourUrl);
  };

  const getData = async () => {
    const res = await RequestHelper.get('/tours/list', {});
    if (res.data?.success) {
      setList(res.data?.data?.list.map(val => ({
        ...val,
        id: val.ID,
      })));
      try {
        const orderString = res.data?.data?.order?.list;
        setOrder(JSON.parse(orderString));
      } catch (err) { }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="tour-list">
      <div className="container py-5">
        <div className="row justify-content-center">
          {
            list && list.length > 0 && (
              list.map((item, nIndex) => {
                return(
                  <div className="col-md-4" key={nIndex}>
                    <TourCard
                      data={{
                        name: item.tourName,
                        token: url2Token(item.tourUrl),
                        imgUrl: item.tourThumbnail,
                      }}
                      hasGroupBtn={hasGroupBtn}
                      takeTheTourText={takeTheTourText}
                    />
                  </div>
                );
              })  
            )
          }
        </div>
      </div>
    </div>
  )
}

export default TourList;