import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';


import './MainBrokerDisconnectModal.scss';

interface Props {
  open: boolean,
  onClose(): void,
  sessionData?: any,
  [additionProps: string]: any,
}

const MainBrokerDisconnectModal = (props : Props) => {
  const url = (() => {
    if (!props.sessionData || !props.sessionData.tourUrl) return '';
    const parts = props.sessionData.tourUrl.split('/');
    const token = parts[parts.length - 1];
    return `https://tour.theatro360.com/tour/${token}`;
  })();
  return (
    <CustomModal
      show={props.open}
      onHide={props.onClose}
      centered
      backdrop="static"
      className="reset-password-modal"
    >
      <Modal.Body className="main-broker-disconnect-modal">
        <h3 className="text-center">Thank you for joining</h3>
        <p className="text-center">Your session has finished.</p>
        {/* <p className="text-center"><a href={url}>{url}</a></p> */}
      </Modal.Body>
    </CustomModal>
  );
};

const CustomModal = styled(Modal)`
  .form-group {
    margin-bottom: 0.8rem;

    label {
      margin: 0;
      color: white;
      opacity: 0.8;
      font-size: 0.8rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
      border-bottom: 1px solid white;
      border-radius: 0;
      padding: 0;
      color: white;
      height: 1.5rem;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  Button {
    background: white;
    width: 100%;
    color: orange;
    margin-top: 1rem;
    border: 1rem;
    height: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .signin-btn {
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`

export default MainBrokerDisconnectModal;
