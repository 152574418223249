import * as dotenv from 'dotenv';

dotenv.config();
const CONFIG: any = {};

CONFIG['BASE_URL'] = 'https://guide.theatro360.com';
CONFIG['API_URL'] = 'https://api-guide.theatro360.com/api';
CONFIG['API_URL_NEXT'] = 'https://theatro-next.vercel.app/api';
// CONFIG['API_URL'] = 'http://localhost:8080/api';
// CONFIG['API_URL'] = 'https://015d82164332.ngrok.io/api';

CONFIG['VOICE_SERVICE_URL'] = 'https://voice.burgess-shared-tour.devserver.london';
// CONFIG['TOUR_DEVSERVER_URL'] = 'http://localhost:8080';
CONFIG['TOUR_DEVSERVER_URL'] = 'https://tour.theatro360.com';
// CONFIG['TOUR_DEVSERVER_URL'] = 'https://burgess.theatro360.com';
CONFIG['SOCKET_HOSTNAME'] = 'api-guide.theatro360.com';
// CONFIG['SOCKET_HOSTNAME'] = 'localhost:8088';

// DEFAULT THEATRO LOGO
CONFIG['IMAGE'] = 'https://codesigned-backup.fra1.digitaloceanspaces.com/cd-cms-nododb/nc/theatro360_tourguide_vr_YlbU/db/uploads/xa2__sites/Image_5DLJIu_pLutZvU.png'

CONFIG['PLAYBOOKS'] = false;
CONFIG['SUBDOMAIN'] = '';

if (window.location.hostname === 'burgess-shared-tour.devserver.london') {
  CONFIG['BASE_URL'] = process.env.REACT_APP_BASE_URL || 'https://burgess-shared-tour.devserver.london';
  CONFIG['API_URL'] = process.env.REACT_APP_API_URL || 'https://api.burgess-shared-tour.devserver.london/api';
  CONFIG['VOICE_SERVICE_URL'] = process.env.REACT_APP_VOICE_SERVICE_URL || 'https://voice.burgess-shared-tour.devserver.london';
  CONFIG['TOUR_DEVSERVER_URL'] = process.env.TOUR_DEVSERVER_URL || 'https://tour.theatro360.com';
  CONFIG['SOCKET_HOSTNAME'] = 'api.burgess-shared-tour.devserver.london';
}

if (window.location.hostname === 'guide.burgess.theatro360.com') {
  CONFIG['BASE_URL'] = process.env.REACT_APP_BASE_URL || 'https://guide.burgess.theatro360.com';
  CONFIG['API_URL'] = process.env.REACT_APP_API_URL || 'https://api-guide.burgess.theatro360.com/api';
  CONFIG['VOICE_SERVICE_URL'] = process.env.REACT_APP_VOICE_SERVICE_URL || 'https://voice.burgess-shared-tour.devserver.london';
  // CONFIG['TOUR_DEVSERVER_URL'] = process.env.TOUR_DEVSERVER_URL || 'https://burgess.theatro360.com';
  CONFIG['TOUR_DEVSERVER_URL'] = 'https://tour.theatro360.com';
  CONFIG['SOCKET_HOSTNAME'] = 'api-guide.burgess.theatro360.com';
}

if (window.location.hostname === 'guide.theatro360.com') {
  CONFIG['BASE_URL'] = 'https://guide.theatro360.com';
  CONFIG['API_URL'] = 'https://api-guide.theatro360.com/api';
  CONFIG['VOICE_SERVICE_URL'] = 'https://voice.burgess-shared-tour.devserver.london';
  CONFIG['TOUR_DEVSERVER_URL'] = 'https://tour.theatro360.com';
  CONFIG['SOCKET_HOSTNAME'] = 'api-guide.theatro360.com';
}

if (window.location.hostname === 'accor-guide.theatro360.com') {
  CONFIG['BASE_URL'] = 'https://accor-guide.theatro360.com';
  CONFIG['API_URL'] = 'https://api-accor-guide.theatro360.com/api';
  CONFIG['VOICE_SERVICE_URL'] = 'https://voice.burgess-shared-tour.devserver.london';
  CONFIG['TOUR_DEVSERVER_URL'] = 'https://tour.theatro360.com';
  CONFIG['SOCKET_HOSTNAME'] = 'api-accor-guide.theatro360.com';
}

export default CONFIG;
