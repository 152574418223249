import React, { Component } from 'react';
import classnames from 'classnames';
import GlobalEvent from 'js-events-listener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CONFIG from '../../../config';
import { faTimes, faMinus, faExpand, faVolumeUp, faVolumeOff, faVideo, faVideoSlash, faVolumeDown } from '@fortawesome/free-solid-svg-icons'
import './VideoChatPopup.scss';
import DefaultAvatarSvg from '../../../assets/images/default-avatar-filled.svg';
// const IFRAME_ORIGIN = 'https://192.168.1.9:3001';
// const IFRAME_URL_PATH = `https://192.168.1.9:3001/video-chat`;

const IFRAME_ORIGIN = `${window.location.protocol}//${window.location.host}`;
const IFRAME_URL_PATH = `${window.location.protocol}//${window.location.host}/video_conferrence.html`;
// const IFRAME_ORIGIN = CONFIG['BASE_URL'];
// const IFRAME_URL_PATH = `${IFRAME_ORIGIN}/video-chat`;

const DISPLAY = {
  MAXIMIZE: 'MAXIMIZE',
  MINIMIZE: 'MINIMIZE',
  HIDDEN: 'HIDDEN',
};

export default class VideoChatPopup extends Component<any, any> {
  state = {
    displayMode: DISPLAY.HIDDEN,
    didJoined: false,
    showMinimizeIcon: false,
  }

  listUsers = [];

  componentDidUpdate() {
    // @ts-ignore
    if (window.videoCallDisplayMode !== this.state.displayMode) {
      !!this.props.onChangeDisplayMode && this.props.onChangeDisplayMode(this.state.displayMode);
      GlobalEvent.emit('VIDEO_GROUP_DISPLAY_ACTION', this.state.displayMode);
    }
    // @ts-ignore
    window.videoCallDisplayMode = this.state.displayMode;
    if (this.state.displayMode === 'MAXIMIZE') {
      console.log(this.listUsers);
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleEvent);
    GlobalEvent.on('DISCONNECT_VIDEO_CALL', () => {
      this.leaveVideo();
    });
    GlobalEvent.on('CONTROLLER_READY', () => {
      if (!window.location.hostname.includes('localhost')
        && !window.location.hostname.includes('dev.guide.local.dev')
      ) this.init();
    });
    GlobalEvent.on('VIDEO_GROUP_SOUND_TOGGLE', () => {
      const element : HTMLIFrameElement = document.querySelector("#video-chat-room-group");
      if (!element) return;
      if (!element.contentWindow) return;
      console.log('sending event SOUND_TOGGLE')
      element.contentWindow.postMessage(JSON.stringify({
        action: 'SOUND_TOGGLE',
      }), '*')
    });
    GlobalEvent.on('VIDEO_GROUP_VIDEO_TOGGLE', () => {
      const element : HTMLIFrameElement = document.querySelector("#video-chat-room-group");
      if (!element) return;
      if (!element.contentWindow) return;
      console.log('sending event VIDEO_TOGGLE')
      element.contentWindow.postMessage(JSON.stringify({
        action: 'VIDEO_TOGGLE',
      }), '*')
    });
    GlobalEvent.on('MEMBER_INFO', (listUsers) => {
      // @ts-ignore
      this.listUsers = listUsers;
      const element : HTMLIFrameElement = document.querySelector("#video-chat-room-group");
      if (!element) return;
      if (!element.contentWindow) return;
      element.contentWindow.postMessage(JSON.stringify({
        action: 'MEMBER_INFO',
        payload: listUsers,
      }), '*');
    });
    GlobalEvent.on('MAIN_BROKER_DISCONNECTED', () => {
      this.leaveVideo();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleEvent)
  }

  handleEvent = (e) => {
    if (e.origin !== IFRAME_ORIGIN) return;
    const { data } = e;
    try {
      const parsedData = JSON.parse(data);
      const { action, payload } = parsedData;
      if (action !== 'VIDEO_CALL_ACTION') return;
      switch(payload) {
        case 'VIDEO_GROUP_SOUND_OFF':
          GlobalEvent.emit('VIDEO_GROUP_SOUND_UPDATE', false);
        break;
        case 'VIDEO_GROUP_SOUND_ON':
          GlobalEvent.emit('VIDEO_GROUP_SOUND_UPDATE', true);
        break;
        case 'VIDEO_GROUP_VIDEO_OFF':
          GlobalEvent.emit('VIDEO_GROUP_VIDEO_UPDATE', false);
        break;
        case 'VIDEO_GROUP_VIDEO_ON':
          GlobalEvent.emit('VIDEO_GROUP_VIDEO_UPDATE', true);
        break;
        case 'CLOSE':
          this.leaveVideo();
        break;
        case 'MAXIMIZE_CALL':
          this.setState({ displayMode: DISPLAY.MAXIMIZE });
        break;
        case 'I_HAVE_JOINED':
          if (!this.state.showMinimizeIcon) this.setState({ showMinimizeIcon: true });
          GlobalEvent.emit('MEMBER_INFO', this.listUsers || []);
        break;
        default:
      }
    } catch(err) {
      console.log(err);
    }
  }

  init = async () => {
    this.setState({ displayMode: DISPLAY.MAXIMIZE });
  }

  leaveVideo = () => {
    this.setState({ displayMode: DISPLAY.HIDDEN, showMinimizeIcon: false });
  }

  minimize = () => {
    this.setState({ displayMode: DISPLAY.MINIMIZE });
  }

  toggleMinMax = () => {
    const { displayMode } = this.state;
    this.setState({ displayMode: displayMode === DISPLAY.MINIMIZE ? DISPLAY.MAXIMIZE : DISPLAY.MINIMIZE });
  }

  iframeRef

  render() {
    const { displayMode, showMinimizeIcon } = this.state;
    const { tourSession, yourName, room, yourAvatar } = this.props;
    if (displayMode === DISPLAY.HIDDEN) return null;
    if (displayMode === DISPLAY.MAXIMIZE || displayMode === DISPLAY.MINIMIZE) {
      const colorIcon = displayMode === DISPLAY.MAXIMIZE ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)';
      return (
        <>
          <div className={classnames('video-chat-popup-group__overlay', displayMode === DISPLAY.MINIMIZE ? 'wrapper-new-minimized' : '')} />
          <div
            className={classnames(
              "video-chat-popup-group__overlay video-chat-popup-group__maximize d-flex flex-column",
              displayMode === DISPLAY.MINIMIZE ? 'new-minimized' : ''
            )}
          >
            <div className="first-row">
              <span></span>
              {showMinimizeIcon && (
                <>
                  {displayMode === DISPLAY.MAXIMIZE && <span className={"proceed-to-tour"} onClick={this.toggleMinMax}>Proceed to Tour</span>}
                  <button className={"minimize-icon-btn"} onClick={this.toggleMinMax}>
                    <FontAwesomeIcon icon={displayMode === DISPLAY.MAXIMIZE ? faMinus : faExpand} color={colorIcon} />
                  </button>
                </>
              )}
              {/* {showMinimizeIcon && <button className="minimize-icon-btn" onClick={this.leaveVideo}><FontAwesomeIcon icon={faTimes} color={colorIcon} /></button>} */}
            </div>
            <div className="second-row d-flex flex-column justify-content-center align-items-center" style={{ padding: 0 }}>
              <iframe
                id="video-chat-room-group"
                ref={ref => this.iframeRef = ref}
                title="Video Chat Room"
                // src={`${IFRAME_URL_PATH}?name=${yourName}&room=${room}&main=${tourSession.broker.name}`}
                src={`${IFRAME_URL_PATH}?name=${yourName}&room=${room}&main=${tourSession.broker.name}&default_avatar=${yourAvatar || DefaultAvatarSvg}&mobile_layout=${window.innerWidth <= 600 ? 'true' : 'false'}`}
                style={{ width: '100%', height: '100%', border: 'none' }}
                allow="camera *;microphone *"
              />
            </div>
          </div>
        </>
      )
    }
  }
}