import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Alert, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import _ from 'lodash';
import styled from 'styled-components';

import * as CONSTANT from '../../../constants';
import ModalListUser from './ModalListUser';
import RequestHelper from '../../../utils/Request.Utils';
import PlaySvg from '../../../assets/images/new-icons/play.svg';
import groupSessionSvg from '../../../assets/images/btn_group_request.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import * as CONSTANTS from '../../../constants';

type Props = {
  tourInfo: {
    ID: number,
    broker: {
      ID: number,
      avatar: string,
      country: string,
      email: string,
      name: string,
      phone: string,
      role: string,
      createdAt: string,
      updatedAt: string
    },
    brokerId: number,
    client: {
      ID: number,
      avatar: string,
      country: string,
      email: string,
      name: string,
      phone: string,
      role: string,
      createdAt: string,
      updatedAt: string
    },
    clientId: number,
    scheduleTime: string,
    socketCode: string,
    status: string,
    tourName: string,
    tourThumbnail: string,
    tourUrl: string,
    createdAt: string,
    updatedAt: string,
    groupName: string,
    isGroup: boolean,
    slug: string,
  },
  clearSession: () => void,
}

function RequestIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.18 79.18" width="40" height="40" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle cx="39.59" cy="39.59" r="39.59" className="cls-1" style={{ fill: CONSTANT.COLOR.MAIN }}></circle>
          <path
            d="M21.86 37.85v-.17-.12-.11a8.74 8.74 0 01.1-1.28L22 36h-.17a7.63 7.63 0 119.66-8.58v.17h1.42v-.25a9 9 0 10-12.69 9.48 13.11 13.11 0 00-9.42 12.57v2.91a.7.7 0 001.4 0v-2.91a11.75 11.75 0 019.49-11.51z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
          <path
            d="M40.79 37.46A9 9 0 1028 45.65a13.15 13.15 0 00-9.35 12.57v2.91a.7.7 0 101.4 0v-2.91a11.73 11.73 0 0119.33-8.93.76.76 0 00.46.16.71.71 0 00.53-.24.7.7 0 00-.08-1 13 13 0 00-4.74-2.57 9 9 0 005.24-8.18zm-1.4 0a7.63 7.63 0 11-7.63-7.63 7.64 7.64 0 017.63 7.63zM49.47 36.75v3.44a.69.69 0 00.35.59.72.72 0 00.32.08.7.7 0 00.37-.11l6.09-4h3.24a6 6 0 006-6v-4.1a6 6 0 00-6-6h-9.65a6 6 0 00-6 6v4.11a6 6 0 005.28 5.99zm.68-1.31a4.7 4.7 0 01-4.65-4.68v-4.11A4.7 4.7 0 0150.19 22h9.65a4.7 4.7 0 014.69 4.69v4.11a4.7 4.7 0 01-4.69 4.69H56.4a.7.7 0 00-.37.11L50.82 39v-2.88a.69.69 0 00-.67-.68z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
          <path
            d="M51.45 28.8a.9.9 0 10-.9.9.9.9 0 00.9-.9zM55 29.7a.9.9 0 10-.9-.9.9.9 0 00.9.9zM59.48 29.7a.9.9 0 10-.9-.9.9.9 0 00.9.9zM55.09 50.5a.72.72 0 00.48-.2.61.61 0 00.21-.47.64.64 0 00-.2-.5l-5.23-5.23a.66.66 0 00-.74-.15.91.91 0 00-.23.15l-5.22 5.22a.67.67 0 00-.2.48.7.7 0 00.2.49.67.67 0 001 0l4.06-4.06v15.65a.69.69 0 101.37 0V46.23l4.06 4.06a.73.73 0 00.44.21zM65.78 56.18a.68.68 0 00-.48-.18.67.67 0 00-.5.2l-4.06 4.06V44.58a.69.69 0 00-1.37 0v15.65l-4.06-4.06a.68.68 0 00-.49-.2.69.69 0 00-.68.69.67.67 0 00.2.48l5.23 5.23a.64.64 0 00.22.14.58.58 0 00.26.06.71.71 0 00.49-.21l5.22-5.22a.65.65 0 00.21-.48.66.66 0 00-.19-.48z"
            className="cls-2"
            style={{ fill: 'white' }}
          ></path>
        </g>
      </g>
    </svg>
  );
}

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94.5"
      height="87"
      viewBox="0 0 63 58"
    >
      <g data-name="Group 68" transform="translate(-297 -349)">
        <g fill={CONSTANT.COLOR.MAIN} data-name="Path 52">
          <path
            d="M31.5 56.5c-4.066 0-8.008-.732-11.717-2.177-3.576-1.392-6.786-3.384-9.54-5.92-2.747-2.528-4.901-5.47-6.405-8.741A25.367 25.367 0 011.5 29c0-3.697.787-7.284 2.338-10.662 1.504-3.272 3.658-6.213 6.404-8.74 2.755-2.537 5.965-4.529 9.541-5.921C23.493 2.232 27.434 1.5 31.5 1.5c4.066 0 8.008.732 11.717 2.177 3.576 1.392 6.786 3.384 9.54 5.92 2.747 2.528 4.901 5.47 6.405 8.741A25.367 25.367 0 0161.5 29c0 3.03-.534 6.004-1.587 8.84a26.587 26.587 0 01-4.442 7.664 29.322 29.322 0 01-6.823 6.02 31.964 31.964 0 01-8.714 3.874c-2.592.721-5.508 1.102-8.434 1.102z"
            transform="translate(297 349)"
          ></path>
          <path
            fill="#fff"
            d="M31.5 3c-3.879 0-7.638.698-11.173 2.075-3.4 1.324-6.452 3.217-9.069 5.626-2.599 2.393-4.637 5.173-6.057 8.263A23.877 23.877 0 003 29c0 3.48.74 6.856 2.201 10.036 1.42 3.09 3.458 5.87 6.057 8.263 2.617 2.41 5.668 4.302 9.07 5.626C23.861 54.302 27.62 55 31.5 55c2.792 0 5.57-.362 8.033-1.047a30.466 30.466 0 008.306-3.692 27.825 27.825 0 006.475-5.712C58.034 40.04 60 34.663 60 29c0-3.48-.74-6.856-2.201-10.036-1.42-3.09-3.458-5.87-6.057-8.263-2.617-2.41-5.668-4.302-9.07-5.626C39.139 3.698 35.38 3 31.5 3m0-3C48.897 0 63 12.984 63 29c0 13.101-9.437 24.173-22.664 27.844C37.724 57.57 34.666 58 31.5 58 14.103 58 0 45.016 0 29S14.103 0 31.5 0z"
            transform="translate(297 349)"
          ></path>
        </g>
        <path
          fill="#fff"
          d="M14 0l14 27H0z"
          data-name="Polygon 2"
          transform="rotate(90 -9.5 355)"
        ></path>
      </g>
    </svg>
  );
}

const TourItem = ({ tourInfo, clearSession }: Props) => {
  const [alert, setAlert] = useState({ isShow: false, status: '', msg: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const history = useHistory();
  const { userInfo } = useSelector((state: any) => ({
    userInfo: state.user.user
  }))

  const getData = async () => {
    const res = await RequestHelper.get(`/tour-session/${tourInfo.ID}/all-members`, {})
    if (res.data && res.data.success) {
      const list = _.uniqWith(res.data.data.filter(val => !!val.name && !!val.email).map(val => ({ avatar: val.avatar, name: val.name, email: val.email, deletedAt: val.deletedAt })), _.isEqual);
      setListUsers(list);
      if (tourInfo.brokerId !== userInfo.ID) {
        clearInterval(_interval.current);
        return;
      }
      const findMeOnline = list.find(val => !!val.email && val.email === userInfo.email && !val.deletedAt);
      const totalNumberOnline = list.filter(val => !val.deletedAt).length;
      if (findMeOnline || totalNumberOnline === 0) {
        setIsWaiting(false);
      } else if (!findMeOnline && totalNumberOnline > 0) {
        setIsWaiting(true);
      }
    }
  };

  const _interval = useRef(null);

  // useEffect(() => {
  //   getData();
  //   // _interval.current = setInterval(() => {
  //   //   getData();
  //   // }, 2000);
  //   // return () => {
  //   //   clearInterval(_interval.current);
  //   // };
  // }, [userInfo.ID]);

  const confirmRequest = () => {
    if (tourInfo.status === CONSTANT.TOUR_STATUS.PENDING) {
      setIsLoading(true);

      if (userInfo.ID === tourInfo.broker.ID) {
        if (tourInfo.isGroup) {
          history.push(`/group/virtual-tour/${tourInfo.ID}/${tourInfo.slug}`);
          return;
        }
        history.push(`/virtual-tour/${tourInfo.ID}`);
      } else {
        if (tourInfo.isGroup) {
          history.push(`/group/virtual-tour/${tourInfo.ID}/${tourInfo.slug}`);
          return;
        }
        RequestHelper
          .post('/tour-session/confirm-request', { id: tourInfo.ID })
          .then((res) => {
            if (!res.data.success) {
              setAlert({ isShow: true, status: 'danger', msg: `A ${CONSTANT.ROLES.broker} is currently reviewing ${tourInfo.tourName} - please try another tour` });
              window.setTimeout(() => {
                setAlert({ ...alert, isShow: false });
              }, 3000);
            } else {
              const tourId = res.data.data.ID;
              history.push(`/virtual-tour/${tourId}`);
            }
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }
    } else {
      if (tourInfo.isGroup) {
        history.push(`/group/virtual-tour/${tourInfo.ID}/${tourInfo.slug}`);
        return;
      }
      history.push(`/virtual-tour/${tourInfo.ID}`);
    }
  }

  const showUsers = async () => {
    setShowUserModal(true);
    const res = await RequestHelper.get(`/tour-session/${tourInfo.ID}/all-members`, {})
    // console.log('res', res);
    if (res.data && res.data.success) {
      const list = _.uniqWith(res.data.data.map(val => ({ avatar: val.avatar, name: val.name, email: val.email })), _.isEqual);
      setListUsers(list);
    }
  }

  return (
    <>
      {isLoading ? (
        <CustomSpinner>
          <Spinner animation="border" variant="info" />
        </CustomSpinner>
      ) : null}
      <div className="tour-item d-flex align-items-start">
        <div className="tour-image">
          <img src={tourInfo.tourThumbnail} />
          <button onClick={() => confirmRequest()}>
            <img src={PlaySvg} alt="" />
            {/* <PlayIcon /> */}
          </button>
          {tourInfo.isGroup && (
            // <img src={groupSessionSvg} className="group-icon" />
            <RequestIcon className="group-icon" fill="var(--primary-color)" />
          )}
          <div className="clear-tour-session" style={{ backgroundColor: CONSTANTS.COLOR.MAIN }} onClick={clearSession}>
            <FontAwesomeIcon icon={faTrash} color="white" />
          </div>
        </div>
        <div className="info">
          {/* <div className="status">
            <label>Status:</label>
            <span className={tourInfo.status.toLowerCase()}>{tourInfo.status}</span>
          </div> */}
          <div className="broker">
            <label>{CONSTANT.ROLES.broker}:</label>
            {tourInfo.broker.name}
          </div>
          {!tourInfo.isGroup &&
            <div className="client">
              <label>Client:</label>
              {tourInfo.client.name}
            </div>}
          <div className="email">
            <label>Email:</label>
            {tourInfo.client.email}
          </div>
          <div className="telephone">
            <label>Telephone:</label>
            {tourInfo.client.phone}
          </div>
          <div className="name">
            <label>Tour:</label>
            {tourInfo.tourName}
          </div>
          <div className="date">
            <label>Date/Time:</label>
            <Moment format="YYYY-MM-DD HH:mm">{tourInfo.updatedAt}</Moment>
          </div>
          {tourInfo.isGroup && <div className="name">
            <label>Group Name:</label>
            {tourInfo.groupName}
          </div>}
          {tourInfo.isGroup && <div className="name">
            <label>Participants:</label>
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={showUsers}>View Users</span>
          </div>}
        </div>

        <Alert variant="danger" show={alert.isShow && alert.status === 'danger'}>{alert.msg}</Alert>
        <ModalListUser
          open={showUserModal}
          onClose={() => setShowUserModal(false)}
          list={listUsers}
          tourName={tourInfo.tourName}
        />
      </div>
    </>
  )
}

const CustomSpinner = styled.div`
  width: 100%;
  height: 150px;
  background: rgba(255,255,255, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px #ddd;

  @media (max-width: 1199px) {
    height: 120px;
  }

  @media (max-width: 991px) {
    width: 95%;
    height: 295px;
  }
`

export default TourItem;
