import RequestHelper from '../../../utils/Request.Utils';
class Logger {
  tourId;
  constructor(tourId) {
    this.tourId = tourId
  }
  
  add(action, data, { createdBy, id, email }) {
    if (!window.location.href.includes('log=1')) return;
    RequestHelper.post('/misc/logs', {
      type: 'SHARED_TOUR',
      name: 'TOUR_ID_' + this.tourId,
      data: {
        action,
        data,
      },
      createdBy,
      createdByID: id,
      createdByEmail: email,
    });
  }
}

export default Logger;