import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      fill='var(--primary-color)'
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path d="M500 227.7c-70.9 0-128.6 57.7-128.6 128.6 0 70.9 57.7 128.6 128.6 128.6 70.9 0 128.6-57.7 128.6-128.6 0-70.9-57.7-128.6-128.6-128.6z" />
      <path d="M500 10C229.4 10 10 229.4 10 500s219.4 490 490 490 490-219.4 490-490S770.6 10 500 10zm227.7 746.6c-13.4 0-24.2-10.8-24.2-24.2 0-111-89.4-197.9-203.5-197.9s-203.5 86.9-203.5 197.9c0 13.4-10.8 24.2-24.2 24.2-13.4 0-24.2-10.8-24.2-24.2 0-67.1 26.3-129.5 74.1-175.7 23.5-22.7 50.9-40.3 80.8-52.3-10-6.6-19.5-14.3-28.1-22.9-33.4-33.4-51.8-77.9-51.8-125.2s18.4-91.7 51.8-125.2c33.4-33.4 77.9-51.8 125.2-51.8s91.7 18.4 125.2 51.8C658.6 264.6 677 309 677 356.3s-18.4 91.7-51.8 125.2c-8.7 8.7-18.1 16.3-28.2 22.9 29.9 12 57.4 29.6 80.8 52.3 47.8 46.2 74.1 108.6 74.1 175.7 0 13.3-10.8 24.2-24.2 24.2z" />
    </svg>
  )
}

export default SvgComponent