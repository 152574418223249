import React, { useState } from 'react';
import styled from 'styled-components';
import PendingSvg from '../../../assets/images/watch.svg';
import PendingActiveSvg from '../../../assets/images/watch-active.svg';
import { useSelector } from 'react-redux';
import ConnectedSvg from '../../../assets/images/connected.svg';
import ConnectedActiveSvg from '../../../assets/images/connected-active.svg';
import AvailableSvg from '../../../assets/images/available.svg';
import AvailableActiveSvg from '../../../assets/images/available-active.svg';
import UnavailableSvg from '../../../assets/images/unavailable.svg';
import UnavailableActiveSvg from '../../../assets/images/unavailable-active.svg';
import HistorySvg from '../../../assets/images/history.svg';
import HistoryActiveSvg from '../../../assets/images/history-active.svg';

import TourIconActiveSvg from '../../../assets/images/360-tours-active.svg';
import TourIconSvg from '../../../assets/images/360-tours-inactive.svg';

import UserIconActiveSvg from '../../../assets/images/users-active.svg';
import UserIconSvg from '../../../assets/images/users-inactive.svg';
import { UserRoles } from '../../../constants';

type Props = {
  curTab: string,
  setTab: Function
}

const SidePanel = ({ curTab, setTab }: Props) => {
  const { userInfo } = useSelector((state: any) => ({
    userInfo: state.user.user,
  }))
  return (
    <div className="side-panel">
      <div className="tabs">
        <TabItem className={"tour " + (curTab === 'Tours' ? 'active' : '')} onClick={() => setTab('Tours')}>
          <span>Tours</span>
        </TabItem>
        {userInfo.role === UserRoles.broker ? (
          <>
            <TabItem className={"connected " + (curTab === 'Pending' ? 'active' : '')} onClick={() => setTab('Pending')}>
              <span>Sessions</span>
            </TabItem>
            {/* <TabItem className={"connected " + (curTab === 'Connected'? 'active': '')} onClick={() => setTab('Connected')}>
              <span>Connected</span>
            </TabItem>
            <TabItem className={"history " + (curTab === 'Completed'? 'active': '')} onClick={() => setTab('Completed')}>
              <span>History</span>
            </TabItem> */}
          </>
        ) : userInfo.role === UserRoles.admin ? (
          <>
            <TabItem className={"connected " + (curTab === 'Pending' ? 'active' : '')} onClick={() => setTab('Pending')}>
              <span>Sessions</span>
            </TabItem>
            {/* <TabItem className={"connected " + (curTab === 'Connected'? 'active': '')} onClick={() => setTab('Connected')}>
                <span>Connected</span>
              </TabItem>
              <TabItem className={"history " + (curTab === 'Completed'? 'active': '')} onClick={() => setTab('Completed')}>
                <span>History</span>
              </TabItem> */}
            <TabItem className={"user " + (curTab === 'Users' ? 'active' : '')} onClick={() => setTab('Users')}>
              <span>Users</span>
            </TabItem>
          </>
        ) : null}

      </div>
    </div>
  )
}

const TabItem = styled.div`
  height: 80px;
  background-repeat: no-repeat;
  background-position: center calc(50% - 3px);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  color: rgba(29, 53, 94, 0.55);
  cursor: pointer;
  padding: 0.5rem 0;

  &.active {
    background-color: rgba(0, 175, 211, 0.21);
  }

  &.tour {
    background-image: url('${TourIconSvg}');

    :hover, &.active {
      background-image: url('${TourIconActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.user {
    background-image: url('${UserIconSvg}');

    :hover, &.active {
      background-image: url('${UserIconActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.pending {
    background-image: url('${PendingSvg}');

    :hover, &.active {
      background-image: url('${PendingActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.connected {
    background-image: url('${ConnectedSvg}');

    :hover, &.active {
      background-image: url('${ConnectedActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.available {
    background-image: url('${AvailableSvg}');

    :hover, &.active {
      background-image: url('${AvailableActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.unavailable {
    background-image: url('${UnavailableSvg}');

    :hover, &.active {
      background-image: url('${UnavailableActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  &.history {
    background-image: url('${HistorySvg}');

    :hover, &.active {
      background-image: url('${HistoryActiveSvg}');
      color: rgb(29, 53, 94);
    }  
  }

  @media (max-width: 991px) {
    height: 70px;
    font-size: 0.5rem;
    background-position: center calc(50% - 5px);
  }
`
export default SidePanel;