import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import CONFIG from '../../config';
import NavMenu from './../../sharedComponents/NavMenu';
import {
  loginUserDialogAction,
} from '../../store/dialog/actions';

import RegisterModal from '../../sharedComponents/RegisterModal';
import SigninModal from '../../sharedComponents/SigninModal';
import ThankyouModal from '../../sharedComponents/ThankyouModal';
import ForgotPasswordModal from '../../sharedComponents/ForgotPasswordModal';
import ResetPasswordModal from '../../sharedComponents/ResetPasswordModal';
import RequestHelper from '../../utils/Request.Utils';
import { getTourToken } from '../../utils/Common.Utils';

declare var TourSDK;

const TourView = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [embedUrl, setEmbedUrl] = useState(`${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${token}`);

  const getTourData = useCallback(async (tk) => {
    let tours = [];
    try {
      const res = await RequestHelper.get("/tours/list", {});
      tours = _.get(res, "data.data.list", []);
    } catch (error) { }
    const tourItem = tours.find(i => getTourToken(i.tourUrl) === tk);

    let url = `${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${tk}`;
    if (tourItem?.support3D || tk === '81-DN0T') {
      url += "?3D=true";
    }
    setEmbedUrl(url);
  }, [])

  useEffect(() => {
    if (!token) return;

    getTourData(token);
    const tourControl = new TourSDK(`#tour-${token}`, CONFIG["TOUR_DEVSERVER_URL"]);
    tourControl.on('REQUEST_SHARED_TOUR', () => {
      console.log('User has requested shared tour session');
      if (!localStorage.token) {
        dispatch(loginUserDialogAction(true));
      } else {
        history.push(`/request-tour?url=${CONFIG["TOUR_DEVSERVER_URL"]}/tour/${token}`);
      }
    });
  }, [token]) // eslint-disable-line

  return (
    <>
      <NavMenu hasBack />
      <Container>
        <iframe
          key={embedUrl}
          title={token}
          id={`tour-${token}`}
          src={embedUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />

        <RegisterModal role="client" />
        <SigninModal role="client" />
        <ThankyouModal type="tour-session" />
        <ForgotPasswordModal />
        <ResetPasswordModal />
      </Container>
    </>
  )
}

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 70px);
`

export default TourView
