import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import NavMenu from './../../sharedComponents/NavMenu';
import ChattingPanel from './ChattingPanel';
import UserPanel from './UserPanel/UserPanel';
import MainPanel from './MainPanel';
import MainBrokerDisconnectModal from './MainPanel/MainBrokerDisconnect';
import KickUserModal from './MainPanel/KickUserModal';
import {
  setTourSessionAction,
  setSocketCodeAction,
  setSocketAction,
  setTwilioConnectionAction,
  setTourControllerAction,
  setTourTokenAction,
  setEventTypeAction,
} from '../../store/virtualTour/actions';
import {
  voiceChattingDialogAction,
  videoChattingDialogAction,
} from '../../store/dialog/actions';
import { DialogNames } from '../../store/dialog/types';
import VideoChattingModal from '../../sharedComponents/VideoChattingModal';
import RequestHelper from '../../utils/Request.Utils';
import { generateVoiceName } from '../../utils/Common.Utils';
import * as CONSTANTS from '../../constants';
import CONFIG from '../../config';
import UserAddNameModal from "./MainPanel/UserAddNameModal";
import StopSessionIcon from './MainPanel/StopSessionIcon';
import { getTourToken } from '../../utils/Common.Utils';
import Logger from './MainPanel/Logger';

const { BroadcastChannel } = require('broadcast-channel');

declare var io;
declare var Twilio;

let _userModalTimeout;

const VirtualTour = () => {
  // @ts-ignore
  let { id } = useParams();
  const logger = useRef(new Logger(id));
  const dispatch = useDispatch();
  const { userState, virtualTourState } = useSelector((state: any) => ({
    userState: state.user,
    virtualTourState: state.virtualTour,
  }));
  const [groupNameDataModal, setGroupNameDataModal] = useGlobalState({ open: false, firstTime: false }, 'group-name-modal');
  const [alert, setAlert] = useState({ isShow: false, status: 'success', msg: '' });
  const [isCalling, setIsCalling] = useState(false);
  const [isTabDuplicate, setIsTabDuplicate] = useState(false);
  const [videoChatting, setVideoChatting] = useState({
    isRequesting: false,
    isConnected: false,
  });
  const [showWaiting, setShowWaiting] = useState(true);
  const [mainBrokerDisconnectModal, setMainBrokerDisconnectModal] = useState(false);
  const [lockControl, setLockControl] = useGlobalState(true, 'group-lock-control');
  const [showKickUserModal, setShowKickUserModal] = useState(false);
  const [userAddNameModalData, setUserAddNameModalData] = useGlobalState({
    open: false,
    name: '',
    email: '',
    avatar: '',
  }, 'guest-name');
  const needCloseRef = useRef(false);
  const myControlID = useRef('');
  useEffect(() => {
    if (userAddNameModalData.name) myControlID.current = userAddNameModalData.name;
    else myControlID.current = `${userState.user.ID}-${userState.user.name}`;
  }, [userAddNameModalData.name, userState.user])

  const getMyControlID = () => {
    return myControlID.current;
  };


  const usersPanelRef = useRef(null);

  const onAcceptVideoCall = () => {
    GlobalEvent.emit('ACCEPT_CALL');
  };

  useEffect(() => {
    window.onbeforeunload = function (event) {
      // localStorage.setItem('ended', new Date().toDateString());
      if (!virtualTourState.socket) return;
      if (!getMyControlID()) return;
      virtualTourState.socket.emit("MEMBER_PING", {
        event: 'REALLY_OFFLINE',
        data: {
          // userId: userState.user.ID,
          isMainBroker: virtualTourState.tourSession.broker.ID === userState?.user?.ID,
          controlID: getMyControlID(),
        },
      });

      // For Safari
      return undefined;
    };
  }, [virtualTourState, userState]);

  useEffect(() => {
    const bc = new BroadcastChannel('virtual_tour_' + id);
    bc.onmessage = (eventMessage) => {
      console.log(eventMessage);
      if (eventMessage.type === 'joined') {
        bc.postMessage({ type: 'need_closed' });
      }
      if (eventMessage.type === 'need_closed') {
        needCloseRef.current = true;
        window.alert('There is already another Tab running. Please use only one tab for this Virtual Tour session');
        setIsTabDuplicate(true);
        bc.close();
      }
    }
    bc.postMessage({ type: 'joined' });
    return () => {
      bc.close();
    };
  }, [])

  const getTourSessionDetail = async () => {
    async function fetchData() {
      const tour_session_res = await RequestHelper.get(`/tour-session/${id}`, {});
      if (!tour_session_res.data.success) {
        console.log(tour_session_res.data.error);
        setAlert({ isShow: true, status: 'danger', msg: tour_session_res.data.error });
        window.setTimeout(() => {
          setAlert({ ...alert, isShow: false });
        }, 3000);
      } else {
        dispatch(setTourSessionAction(tour_session_res.data.data));
        const extractTourToken = getTourToken(tour_session_res.data.data.tourUrl);
        console.log('extractTourToken', extractTourToken);
        dispatch(setTourTokenAction(extractTourToken));
        dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.INIT));
      }

      const tour_session_start_res = await RequestHelper.post(`/tour-session/${id}/start`, {});
      if (!tour_session_start_res.data.success) {
        console.log(tour_session_start_res.data.error);
        setAlert({ isShow: true, status: 'danger', msg: tour_session_start_res.data.error });
        window.setTimeout(() => {
          setAlert({ ...alert, isShow: false });
        }, 3000);
      } else {
        dispatch(setSocketCodeAction(tour_session_start_res.data.data.socketCode));
        dispatch(setSocketAction(io(`${CONFIG['SOCKET_HOSTNAME']}/${tour_session_start_res.data.data.socketCode}`)));
        // console.log('tour_session_res.data.data', tour_session_res.data.data);
        const broker = tour_session_res.data.data.broker;
        const controllerName = `${broker.ID}-${broker.name}`;
        dispatch(setTourControllerAction(controllerName));
        localStorage.setItem("controller", controllerName);
        setLockControl(controllerName !== getMyControlID());
        if (controllerName !== getMyControlID()) {
          GlobalEvent.emit('CONTROLLER_READY');
        } else {
          setGroupNameDataModal({ firstTime: true, open: true });
        }
      }
    }
    await fetchData();
  }

  useEffect(() => {
    if (needCloseRef.current) return;
    if (!userState.token) {
      clearTimeout(_userModalTimeout);
      _userModalTimeout = setTimeout(() => {
        setUserAddNameModalData({
          open: true,
          name: '',
          email: '',
          avatar: '',
        });
      }, 2000);
      return;
    } else {
      clearTimeout(_userModalTimeout);
    }
    // close modal when local state is synced
    if (userAddNameModalData.open && !userAddNameModalData.name && !userAddNameModalData.email) {
      setUserAddNameModalData({
        open: false,
        name: '',
        email: '',
        avatar: '',
      })
    }
    getTourSessionDetail();

  }, [id, userState.token]) // eslint-disable-line

  const onOnline = useCallback((msg, socket) => {
    console.log("ONLINE", msg);
    if (!msg || !msg.Name) return;
    console.log('userState.user.ID', userState.user.ID, userState.user.name);
    console.log('userAddNameModalData', userAddNameModalData.name);
    let shouldEmitEvent = false;
    if (!!userState.user.name && userState.user.name !== msg.Name) shouldEmitEvent = true;
    else if (!!userAddNameModalData.name && userAddNameModalData.name !== msg.Name) shouldEmitEvent = true;
    if (shouldEmitEvent) {
      console.log(msg.Name, userState.user.name);
      GlobalEvent.emit('USER_ONLINE', msg.Name);
    }
    usersPanelRef && usersPanelRef.current && usersPanelRef.current.getUsers();
  }, [showWaiting, userState]);

  const onVideoCall = useCallback((msg, socket) => {
    console.log('onVideoCall', msg);
    // @ts-ignore
    const isVideoCallModalOpen = !!window.videoCallDisplayMode && window.videoCallDisplayMode !== 'HIDDEN';
    if (!!msg && msg.name !== userState.user.name && !videoChatting.isConnected && !isVideoCallModalOpen) {
      setVideoChatting({
        isConnected: false,
        isRequesting: true,
      });
      console.log('dispatch(videoChattingDialogAction({isOpened: true}))');
      dispatch(videoChattingDialogAction({ isOpened: true }));
    }
  }, [videoChatting, userState]);

  const _pingOnlineInterval = useRef(undefined);

  useEffect(() => {
    if (!virtualTourState.socket) return;

    const socket = virtualTourState.socket;
    // socket.connect();
    // sending message out
    socket.emit("ONLINE", userState.token ? {
      id: userState.user.ID,
      token: localStorage.token,
      email: userState.user.email,
      name: userState.user.name,
    } : {
      email: userAddNameModalData.email,
      name: userAddNameModalData.name,
      avatar: userAddNameModalData.avatar,
    });

    // receiving message
    socket.on("ONLINE", msg => {
      onOnline(msg, socket);
    });

    socket.on("OFFLINE", (msg) => {
      // console.log("OFFLINE", msg);
      // GlobalEvent.emit('USER_OFFLINE', msg.Name || msg.name);
      // dispatch(setEventTypeAction(CONSTANTS.VIRTUAL_TOUR_CONTROL_EVENT.INIT));
      // usersPanelRef && usersPanelRef.current && usersPanelRef.current.getUsers();
      // const userName = msg.Name || msg.name;
      // const mainBrokerName = virtualTourState.tourSession.broker.name;
      // if (userName === mainBrokerName) {
      //   console.log('main broker disconnect');
      //   setMainBrokerDisconnectModal(true);
      // }
    });

    socket.on("VIDEO_CALL", msg => {
      onVideoCall(msg, socket);
    });

    socket.on("VOICE_READY", (msg) => {
      console.log("VOICE_READY", msg);
      dispatch(voiceChattingDialogAction({ isOpened: true, role: 'master', action: 'start' }));
      dispatch(voiceChattingDialogAction({ isOpened: true, role: 'slave', action: 'start' }));
      setIsCalling(true);

      setTimeout(() => {
        dispatch(voiceChattingDialogAction({ isOpened: false }));
      }, 2000);
    })

    socket.on("SESSION_PING", msg => {
      console.log("SESSION_PING", msg);
      if (!msg || !msg.data) return;
      if (msg.data.event === 'USER_ONLINE_VIDEO_CALL' || msg.data.event === 'USER_OFFLINE_VIDEO_CALL') {
        console.log(msg.data, msg.data.name !== userState.user.name && msg.data.name !== userAddNameModalData.name);
        if (msg.data.name !== userState.user.name && msg.data.name !== userAddNameModalData.name) {
          GlobalEvent.emit(msg.data.event, msg.data.name);
        }
      }
      if (msg.data.event === 'TRANSFER_CONTROL_IN_GROUP') {
        GlobalEvent.emit('TRANSFER_CONTROL_IN_GROUP', msg.data);
      }
    });

    socket.on("MEMBER_PING", msg => {
      if (msg.event === "REALLY_OFFLINE") {
        if (msg.data?.isMainBroker) {
          setMainBrokerDisconnectModal(true);
          GlobalEvent.emit('MAIN_BROKER_DISCONNECTED');
          typeof socket.disconnect === 'function' && socket.disconnect();
        } else {
          usersPanelRef && usersPanelRef.current && usersPanelRef.current.getUsers();
          const controlID = msg.data?.controlID;
          if (!!controlID) {
            const controlName = controlID.includes('-') ? controlID.split('-')[1] : controlID;
            GlobalEvent.emit('USER_OFFLINE', controlName);
          }
        }
      }
      if (msg.event === 'KICK_USER') {
        const controlID = msg.data?.controlID;
        if (controlID === myControlID.current) {
          const kickedName = controlID.includes('-') ? controlID.split('-')[1] : controlID;
          localStorage.setItem('kick_user_group_'+id, kickedName);
          localStorage.setItem('time_kick_user_group_'+id, String(new Date().getTime()));
          setShowKickUserModal(true);
        }
      }
    });

    _pingOnlineInterval.current = setInterval(() => {
      socket.emit("MEMBER_PING", {
        event: 'STILL_THERE',
        data: {
          isMainBroker: false,
          controlID: '',
        },
      });
    }, 2000);

    return () => {
      clearInterval(_pingOnlineInterval.current);
    };

  }, [virtualTourState.socket]) // eslint-disable-line

  const onAnomyousUserUpdateInfo = async (name, email, avatar) => {
    setUserAddNameModalData({
      name, email,
      open: false,
      avatar,
    })
    await getTourSessionDetail();
  }

  if (isTabDuplicate) {
    return null;
  }

  return (
    <>
      <NavMenu
        renderRight={() => {
          return (
            <StopSessionIcon />
          );
        }}
      />
      <div className="main-container" style={{ padding: 25 }}>
        <div className="main-page-section">
          {window.innerWidth >= 600 && <UserPanel ref={usersPanelRef} />}
          <MainPanel
            isCalling={isCalling}
            disableVoiceCall={videoChatting.isConnected}
            onLeaveVideoCall={() => {
              setVideoChatting({
                isRequesting: false,
                isConnected: false,
              })
            }}
          />
        </div>
      </div>

      <Alert variant="success" show={alert.isShow && alert.status === 'success'}>{alert.msg}</Alert>
      <Alert variant="danger" show={alert.isShow && alert.status === 'danger'}>{alert.msg}</Alert>

      <UserAddNameModal
        open={userAddNameModalData.open}
        onClose={() => setUserAddNameModalData({
          ...userAddNameModalData,
          open: false,
        })}
        onAddInfo={onAnomyousUserUpdateInfo}
        onStillBeKicked={() => {
          console.log('onStillBeKicked');
          setShowKickUserModal(true);
        }}
      />
      <VideoChattingModal onAccept={onAcceptVideoCall} />
      {userState?.user?.role !== 'broker' &&
      <MainBrokerDisconnectModal
        open={mainBrokerDisconnectModal}
        onClose={() => setMainBrokerDisconnectModal(false)}
        sessionData={virtualTourState?.tourSession}
      />}  
      <KickUserModal
        open={showKickUserModal}
        onClose={() => setShowKickUserModal(false)}
      />
    </>
  )
}

export default VirtualTour
