import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import RequestHelper from '../../../utils/Request.Utils';
import { getTourToken } from '../../../utils/Common.Utils';
import './TourCRUD.scss';
import TourCard from '../../Homepage/TourCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import CONFIG from '../../../config';
import Select from 'react-select';
import * as CONSTANTS from '../../../constants';
const _ = require('lodash');

const TourCRUD = ({ searchKeyword }) => {
  const [list, setList] = useState([]);
  const [order, setOrder] = useState([]);
  const [tourGroupOptions, setGroupOptions] = useState([
    { label: 'View All', value: 0, tourIds: [] },
    { label: '+ Add Section', value: -1, tourIds: [] },
  ]);
  const [selectedGroup, setSelectedGroup] = useState<{ label: string, value: number, tourIds?: Array<number> }>({ label: 'View All', value: 0, tourIds: [] });
  const [upsertData, setUpsertData] = useState({
    showUI: false,
    data: {
      id: 0,
      tourToken: '',
      tourName: '',
      tourThumbnail: '',
      support3D: false,
    },
  });
  const [groupData, setGroupData] = useState({
    id: 0,
    name: '',
    tourIds: [],
    showUI: false
  });
  const { userInfo } = useSelector((state: any) => ({
    userInfo: state.user.user
  }));

  const url2Token = (tourUrl) => {
    return getTourToken(tourUrl);
  };

  const token2Url = (tourToken) => {
    return `${CONFIG['TOUR_DEVSERVER_URL']}/tour/${tourToken}`;
  }

  const getData = async () => {
    const res = await RequestHelper.get('/tours/list', {});
    // console.log(res.data);
    if (res.data?.success) {
      const newList = res.data?.data?.list.map(val => ({
        ...val,
        id: val.ID,
        tourToken: url2Token(val.tourUrl),
      }))
      setList(newList);
      try {
        const orderString = res.data?.data?.order?.list;
        setOrder(JSON.parse(orderString));
      } catch (err) { }
      return newList
    }
  };

  const getGroupData = async (tourList) => {
    const res = await RequestHelper.get('/tours/group', {});
    console.log('getGroupData', res.data);
    if (!res.data.success) return;
    const { list } = res.data.data;
    setGroupOptions([
      { label: 'View All', value: 0 },
      ...list.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(val => ({
        label: val.name,
        value: val.ID,
        tourIds: (() => {
          if (val.tourIDs.length === 0) return [];
          return val.tourIDs.map(id => {
            const find = tourList.find(val => val.ID === id);
            console.log('find', find);
            if (!find) return undefined;
            return {
              label: find.tourName,
              value: find.ID,
            }
          }).filter(val => !!val);
        })(),
      })),
      { label: '+ Add Section', value: -1 },
    ])
  }

  const tourScrollRef = useRef(null);

  useEffect(() => {
    (async () => {
      const l = await getData();
      await getGroupData(l);
    })();
  }, []);

  const onGroupFormSubmit = async e => {
    e.preventDefault();
    const { name, tourIds } = groupData;
    if (!name) return alert('Please add a section name');
    if (tourIds.length === 0) return alert('Please add a at least a tour');
    const res = await RequestHelper.post('/tours/group', {
      id: selectedGroup.value === -1 ? 0 : selectedGroup.value,
      name,
      tourIDs: tourIds.map(val => val.value),
    });
    if (!res.data.success) {
      return alert(res.data.error);
    }
    if (selectedGroup.value === -1) {
      const groupOptions = tourGroupOptions.slice();
      groupOptions.pop();
      groupOptions.push({
        label: name,
        value: res.data.data.ID,
        tourIds: tourIds,
      });
      groupOptions.push({ label: '+ Add Section', value: -1, tourIds: [] },)
      setGroupOptions(groupOptions);
    } else {
      const groupOptions = tourGroupOptions.slice();
      const findIndex = groupOptions.findIndex(val => val.value === selectedGroup.value);
      groupOptions[findIndex].tourIds = tourIds
      groupOptions[findIndex].label = name
      setGroupOptions(groupOptions);
    }
    setSelectedGroup({
      label: name,
      value: res.data.data.ID,
      tourIds,
    });
    setGroupData({
      id: 0,
      name: '',
      tourIds: [],
      showUI: false
    })
  }

  const onFormSubmit = async e => {
    e.preventDefault();
    const { id, tourToken, tourName, tourThumbnail, support3D } = upsertData.data
    console.log({ id, tourToken, tourName, tourThumbnail, support3D });
    const res = await RequestHelper.post('/tours', {
      id, tourUrl: token2Url(tourToken), tourName, tourThumbnail, support3D,
    });
    if (res.data.success) {
      const newList = list.slice();
      const findIndex = newList.findIndex(val => val.id === id);
      if (findIndex === -1) {
        newList.push({
          ...res.data.data,
          tourToken,
        });
        const newOrder = list.slice();
        newOrder.push({ id: res.data.data.id });
        setOrder(newOrder);
      }
      else {
        newList[findIndex] = {
          id, tourToken, tourUrl: token2Url(tourToken), tourName, tourThumbnail, support3D
        }
      }
      setList(newList);
      setUpsertData({
        showUI: false,
        data: {
          id: 0,
          tourToken: '',
          tourName: '',
          tourThumbnail: '',
          support3D: false,
        },
      });
      alert('Succesfully executed');

    } else {
      alert(res.data.error || 'An error has occured');
    }
  }

  const onRemoveTour = async item => {
    const shouldRemove = window.confirm('Are you sure to remove this tour?');
    if (!shouldRemove) return;
    const res = await RequestHelper.post('/tours/list/remove', { id: item.id });
    if (res.data.success) {
      const newList = list.filter(val => val.id !== item.id);
      setList(newList);
      setOrder(order.filter(val => val.id !== item.id));
    } else {
      alert(res.data.error || 'An error has occured');
    }
  }

  const onRemoveTourFromGroup = async item => {
    const shouldRemove = window.confirm(`Are you sure to remove this tour from section: ${selectedGroup.label} ?`);
    if (!shouldRemove) return;
    const newSelectedGroup = {
      ...selectedGroup,
      tourIds: selectedGroup.tourIds.filter((val: any) => val.value !== item.ID),
    }
    setSelectedGroup(newSelectedGroup);
    const groupOptions = tourGroupOptions.slice();
    const findIndex = groupOptions.findIndex(val => val.value === newSelectedGroup.value);
    groupOptions[findIndex].tourIds = newSelectedGroup.tourIds;
    setGroupOptions(groupOptions);

    const res = await RequestHelper.post('/tours/group', {
      id: selectedGroup.value,
      tourIDs: newSelectedGroup.tourIds.map((val: any) => val.value),
    });
    if (res.data.success) {

    } else {
      alert(res.data.error || 'An error has occured');
    }
  }

  const filterByKeyword = (arr) => {
    if (!arr || !arr.length) return [];
    let result = [...arr];
    if (searchKeyword) {
      result = result.filter(i => _.toLower(i.tourName).includes(_.toLower(searchKeyword)));
    }
    return result.sort((a, b) => a.tourName > b.tourName ? 1 : -1);
  }

  const displayedList = (() => {
    if (selectedGroup.value === -1) return [];
    if (selectedGroup.value === 0) return filterByKeyword(list);
    if (selectedGroup.tourIds.length === 0) return [];
    const arr = selectedGroup.tourIds.map(({ value }: any) => {
      const find = list.find(val => val.ID === value);
      return find;
    }).filter(val => !!val);
    return filterByKeyword(arr);
  })();

  return (
    <div className="tour-crud-container">
      {!upsertData.showUI && (
        <div style={{ display: 'flex' }}>
          {selectedGroup.value !== -1 && (
            (selectedGroup.value === 0 ? (
              <button
                style={{ marginRight: 20 }}
                onClick={() => {
                  setUpsertData({ ...upsertData, showUI: true })
                }}
              >
                Add a tour
              </button>
            ) : (
              <button
                style={{ marginRight: 20 }}
                onClick={() => {
                  setGroupData({
                    id: selectedGroup.value,
                    name: selectedGroup.label,
                    tourIds: selectedGroup.tourIds,
                    showUI: true
                  })
                }}
              >
                Select a tour
              </button>
            ))

          )}
          <div style={{ width: 250 }}>
            <Select
              options={tourGroupOptions}
              value={selectedGroup}
              onChange={setSelectedGroup}
            />
          </div>
        </div>
      )}
      {(selectedGroup.value === -1 || groupData.showUI) && (
        <form className="tour-crud-upsert-form" onSubmit={onGroupFormSubmit}>
          <input
            required
            className="input form-group form-control" placeholder="Section Name"
            value={groupData.name} onChange={(e) => setGroupData({ ...groupData, name: e.target.value })}
          />

          <Select
            isMulti={true}
            options={list.map(val => ({
              label: val.tourName,
              value: val.ID,
            })).sort((a, b) => a.label > b.label ? 1 : -1)}
            value={groupData.tourIds.sort((a, b) => a.label > b.label ? 1 : -1)}
            onChange={(data) => {
              setGroupData({
                ...groupData,
                tourIds: data,
              })
            }}
          />

          <div style={{ marginTop: 20 }}>
            <input
              type="submit"
              value={selectedGroup.value === -1 ? "Add Section" : "Update Section"}
            />

            <button
              className="cancel" onClick={e => {
                e.preventDefault();
                setGroupData({
                  id: 0,
                  name: '',
                  tourIds: [],
                  showUI: false,
                });
                setSelectedGroup({ label: 'View All', value: 0 });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      {upsertData.showUI && (
        <form className="tour-crud-upsert-form" onSubmit={onFormSubmit}>
          <input
            required
            className="input form-group form-control" placeholder="Tour Name"
            value={upsertData.data.tourName} onChange={(e) => setUpsertData({ ...upsertData, data: { ...upsertData.data, tourName: e.target.value } })}
          />
          <input
            required
            className="input form-group form-control" placeholder="Tour Token"
            value={upsertData.data.tourToken} onChange={(e) => setUpsertData({ ...upsertData, data: { ...upsertData.data, tourToken: e.target.value } })}
          />
          <label>
            <input
              type="checkbox"
              checked={upsertData.data.support3D}
              onChange={(e) => setUpsertData({ ...upsertData, data: { ...upsertData.data, support3D: e.target.checked } })}
            />
            <span style={{ marginLeft: 15 }}>Support 3D</span>
          </label>
          <div>
            <input type="submit" value="Submit" />
            <button
              className="cancel" onClick={e => {
                e.preventDefault();
                setUpsertData({
                  showUI: false,
                  data: {
                    id: 0,
                    tourToken: '',
                    tourName: '',
                    tourThumbnail: '',
                    support3D: false,
                  },
                });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      <div className="row justify-content-center position-relative" style={{ marginTop: 15 }}>
        {
          displayedList && displayedList.length > 0 && (
            displayedList.map((item, nIndex) => {
              return (
                <div className="col-md-4" key={nIndex}>
                  <TourCard
                    data={{
                      name: item.tourName,
                      token: url2Token(item.tourUrl),
                      imgUrl: item.tourThumbnail,
                    }}
                    hasGroupBtn
                    tourGroup={selectedGroup.value}
                  />
                  <div className="tour-card-remove-icon" onClick={() => {
                    if (selectedGroup.value === 0) onRemoveTour(item)
                    else onRemoveTourFromGroup(item);
                  }}>
                    <FontAwesomeIcon icon={faTrash} color="white" />
                  </div>
                  {selectedGroup.value === 0 && (
                    <div className="tour-card-edit-icon" onClick={() => {
                      setUpsertData({
                        showUI: true,
                        data: { ...item },
                      });
                      const el = document.querySelector('#main-panel');
                      if (!!el) el.scrollTo(0, 0);
                    }}>
                      <FontAwesomeIcon icon={faPen} color="white" />
                    </div>
                  )}
                </div>
              );
            })
          )
        }
      </div>
    </div>
  );
};

export default TourCRUD;
