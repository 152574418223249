import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useGlobalState } from 'js-events-listener/react'

const WhoInControl = () => {

  const { userState } = useSelector((state: any) => ({
    userState: state.user,
  }));

  const [userAddNameModalData] = useGlobalState({
    open: false,
    name: '',
    email: '',
    avatar: '',
  }, 'guest-name');

  const getMyControlID = useCallback(() => {
    if (userAddNameModalData.name) return userAddNameModalData.name;
    return `${userState.user.ID}-${userState.user.name}`;
  }, [userAddNameModalData, userState.user]);

  const controller = (() => {
    if (getMyControlID() === localStorage.controller) return 'You';
    if (!localStorage.controller) return '';
    if (localStorage.controller.includes('-')) {
      const parts = localStorage.controller.split('-');
      return parts.length === 2 ? parts[1] : '';
    }
    return localStorage.controller;
  })();

  return controller ? (
    <span style={{ fontSize: 12 }}>
      {controller === 'You' ? (
        <><b>You</b> are in control</>
      ) : (
        <><b>{controller}</b> is in control</>
      )}
    </span>
  ) : null;
};

export default WhoInControl;